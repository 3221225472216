import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Container, Box } from "@mui/material";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";

import _ from "lodash";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import BarrasDobles from "../../components/Charts/AnalsisRevocacion/BarrasDobles";
import BarraMeta from "../../components/Charts/AnalsisRevocacion/BarraMeta";

import Notifications from "react-notify-toast";

import TimeLine from "../../components/Charts/TimeLine/CompromisosDia";
//import ResumenActividad from "../../data/resumen_compromisos_municipios.json";
import CapturaDia from "../../data/CapturaRegionDia4Dic.json";
import ResumenActividad from "../../data/Activacion4Dic.json";
import Municipios from "../../data/municipio_militantes.json";

import "./styles.css";

const Activacion4Dic = () => {
  const [isLoading, setLoading] = useState(false);
  const [isPrintLoading, setPrintLoading] = useState(false);
  const [filtro, setFilter] = useState({ value: 1, label: "Region1" });
  const [availableFilters, setAvailableFilters] = useState([
    { value: null, label: "Seleccione" },
  ]);

  const [dataRevocacion, setDataRevocacion] = useState(null);
  const [dataDiaRevocacion, setDataDiaRevocacion] = useState(null);

  const [dataMunicipio, setDataMunicipio] = useState([]);
  const [chartTendencia, setChartTendencia] = useState(null);

  useEffect(() => {
    //_setmenu(getDataMenu(10, token.menus));
    setLoading(true);

    var Munis = [];
    Municipios.data.forEach((item) =>
      Munis.push({ label: item.Municipio, value: item.cve_mun })
    );
    setDataMunicipio(Munis);

    const infoMunicipio = ResumenActividad.data.filter(
      (item) => item.idMunicipio === filtro.value
    );

    var mcomp = [],
      masis = [],
      text = "";

    console.log({ Compromisos: mcomp, Asistencia: masis });

    setDataRevocacion({ Compromisos: mcomp, Asistencia: masis, text });

    const infoDiaMunicipio = CapturaDia.data.filter(
      (item) => item.Region === filtro.value
    );

    var Categoria = [],
      dataDia = [];
    infoDiaMunicipio.forEach((item, index) => {
      Categoria.push(item.Fecha);
      dataDia.push(item.CompromisosCapturados);
    });

    console.log("=>data: ", { Categoria, data: dataDia });

    setDataDiaRevocacion({
      Categoria,
      data: dataDia,
      Title: `Captura Compromisos por Día Región ${filtro.value}`,
    });

    setLoading(false);
    //setshowMap(true);
  }, [filtro]);

  const sendResults = (item) => {
    //setshowMap(false);
    setFilter(item);
    console.log("=>item: ", item);
  };

  const printDocument = () => {
    setPrintLoading(true);
    console.log("=>Begin Print");
    setPrintLoading(false);
  };

  return (
    <>
      <Notifications></Notifications>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm="12" md="12" lg="12" className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                <CardContent className="p-0">
                  <Container>
                    <Box>
                      <Select
                        options={[
                          { value: 1, label: "Región 1" },
                          { value: 2, label: "Región 2" },
                          { value: 3, label: "Región 3" },
                          { value: 4, label: "Región 4" },
                          { value: 5, label: "Región 5" },
                          { value: 6, label: "Región 6" },
                          { value: 7, label: "Región 7" },
                        ]}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={filtro}
                        onChange={(e) => sendResults(e)}
                        placeholder="Seleccione una opción"
                        isLoading={isLoading}
                      />
                      {!isPrintLoading ? (
                        <button onClick={() => printDocument()}>
                          Exportar
                        </button>
                      ) : (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#0b3cae"
                          loading={true}
                        />
                      )}
                      <br />

                      <div
                        id="divToPrint1"
                        style={{ paddingLeft: "5px", paddingTop: "10px" }}
                      >
                        <br />
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={12} md={12}>
                            <Card variant="outlined">
                              {!isLoading && dataRevocacion !== null && (
                                <TimeLine
                                  dataDiaRevocacion={dataDiaRevocacion}
                                />
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </Box>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Activacion4Dic;
