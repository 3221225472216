import React, { useEffect, useState } from "react";
import ApiExec from "../../utils/ApiExec";
import "../../assets/css/Veda/SiteMD.css";
import swal from "sweetalert";
import Header from "../../components/MainPage/Header";
import { MagicSpinner } from "react-spinners-kit";
import { Grid } from "@mui/material";
import logo from "../../assets/CDESILESABE.png";
/* TABLE START */
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
/* TABLE END */

/* STYLE TABLES START*/
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0000FF", //theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/* STYLE TABLES END*/
const Reconocimientos = (props) => {
  console.log("props: ", props.match.params.id);
  const [data, setData] = useState({
    NombreCompleto: "",
    Cargo: "",
    uuid: "",
    Fecha: "",
    FechaCompleta: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (props.match.params.id !== undefined && props.match.params.id !== null) {
      const getReconocimiento = () => {
        setIsLoading(true);
        const body = {
          filtered: [{ id: "uuid", value: props.match.params.id, filter: "=" }],
        };
        ApiExec(body, "getReconocimientosPaginaQR", {})
          .then(function (res) {
            return res;
          })
          .then((res) => {
            if (res.success && res.results) {
              if (res.data) {
                console.log(res);
                setData(res.data);
              } else {
                swal({
                  title: "¡Ha ocurrido un inconveniente!",
                  text: `¡El reconocimiento no se encontró!`,
                  icon: "warning",
                });
              }
            }
            setIsLoading(false);
          })
          .catch((e) => {
            setIsLoading(false);
            swal({
              title:
                "¡Ha ocurrido un inconveniente, contacte al administrador!",
              text: `"Error: ${e.message}`,
              icon: "warning",
            });
          })
          .finally((e) => {
            setIsLoading(false);
          });
      };
      getReconocimiento();
    }
    else{
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  function createData(name, value) {
    return { name, value };
  }
  const rows = [
    createData("FECHA:", data.FechaCompleta.toUpperCase()),
    createData("NOMBRE:", data.NombreCompleto.toUpperCase()),
    createData("RECONOCIMIENTO: ", data.Cargo.toUpperCase())
  ];

  return (
    <>
      <Header></Header>
      <div id="render-body" className="pt-6 white lighten-5">
        <div className="pt-5 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                {isLoading ? (
                  <>
                    <Grid container justifyContent="center" alignItems="center">
                      <MagicSpinner
                        size={70}
                        color={"#0000FF"}
                        loading={isLoading}
                      ></MagicSpinner>
                    </Grid>
                  </>
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        /* sx={{ minWidth: 1000 }} */
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>
                              <strong>PAN GUANAJUATO</strong>
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                <img height="16px"  src={logo} />
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <>
                            {data.NombreCompleto !== "" ? (
                              <>
                                {rows.map((row) => (
                                  <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                      <strong>{row.name}</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.value}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </>
                            ) : (
                              <>
                                <StyledTableRow key={"SINKEY"}>
                                  <StyledTableCell component="th" scope="row">
                                    {"¡No se encontró el reconocimiento!"}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    {" "}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            )}
                          </>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reconocimientos;
