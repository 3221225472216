import React, { useState } from "react";
import Notifications, { notify } from "react-notify-toast";
import { useHistory } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import { setVars } from "../../utils/GlobalStorage";
import ApiExec from "../../utils/ApiExec";
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import {
  Card,
  CardContent,
  Button,
  FormLabel,
  InputAdornment,
  IconButton,
  Input,
  Grid,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircle from "@mui/icons-material/AccountCircle";
import "../../assets/css/Veda/SiteMD.css";
const Login = () => {
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState({
    values: "",
    showPassword: false,
  });
  const [Loading, setLoading] = useState(false);
  const ariaLabel = { "aria-label": "description" };

  let history = useHistory();
  const validations = () => {
    if (Username.length < 4 || Username.length > 25) {
      notify.show(
        "Error: Usuario requerido y debe contener al menos 4 caracteres y máximo 10.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      setLoading(false);
      return true;
    }

    if (Password.values.length < 6) {
      notify.show("Error: Contraseña requerida.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      setLoading(false);
      return true;
    }
  };

  async function LoadingTrue(e) {
    setLoading(false);
    let res = await setTimeout(function () {
      return true;
    }, 10);
    if (res) {
      setLoading(true);
      signIn(e);
    }
  }

  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    validations();
    const data = {
      usuario: Username,
      password: Password.values,
      type: "web",
    };

    ApiExec(data, "login", {})
      .then(function (res) {
        return res;
      })
      .then((token) => {
        if (token.results) {
          setVars("Token", token);
          setLoading(false);
          history.push(token.user.default_page);
        } else {
          setLoading(false);
          notify.show(
            `Error de acceso: ${token.message}`,
            "error",
            5000,
            "#FFFFFF"
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error de acceso! ${e.message}`, "custom", 5000, {
          background: "#0E1717",
          text: "#FFFFFF",
        });
      });
    // return true;
  };

  const handleChange = (prop) => (event) => {
    setPassword({ ...Password, values: event.target.value });
  };
  const handleClickShowPassword = () => {
    setPassword({
      ...Password,
      showPassword: !Password.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Header></Header>
      <Notifications options={{ zIndex: 999, top: "100pt" }} />
      <div id="render-body" className="pt-6 grey lighten-5">
        <div className="pt-5 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                <Card>
                  <h4 className="card-header gto-blue-darken-2 white-text text-center p-4">
                    Iniciar sesión
                  </h4>
                  <CardContent>
                    <section id="loginForm">
                      <form onSubmit={signIn} className="form-horizontal">
                        <h6 className="grey-text text-center">
                          Ingrese una cuenta para iniciar sesión.
                        </h6>
                        <div style={{ margin: "5pt" }}>
                          <FormLabel className="control-label">
                            * Usuario
                          </FormLabel>
                        </div>
                        <div style={{ margin: "5pt" }} className="sm-form">
                          <Input
                            fullWidth={true}
                            placeholder="Usuario"
                            inputProps={ariaLabel}
                            onChange={(e) => setUsername(e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <AccountCircle />
                              </InputAdornment>
                            }
                          />
                        </div>
                        <div style={{ margin: "5pt" }}>
                          <FormLabel className="control-label">
                            * Contraseña
                          </FormLabel>
                        </div>
                        <div style={{ margin: "5pt" }} className="sm-form">
                          <Input
                            id="standard-adornment-password"
                            type={Password.showPassword ? "text" : "password"}
                            value={Password.values}
                            onChange={handleChange("password")}
                            fullWidth={true}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {Password.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </div>{" "}
                        {Loading ? (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <MetroSpinner
                              size={30}
                              color="#2285E7"
                              loading={Loading}
                            />
                          </Grid>
                        ) : (
                          <>
                            <Button
                              className="btn my-4 blue darken-2 btn-block"
                              onClick={LoadingTrue}
                              loadingPosition={"start"}
                              loading={Loading}
                            >
                              Iniciar sesión
                            </Button>
                          </>
                        )}
                        
                      </form>
                    </section>
                    <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                        <NavLink to="/registro" color="inherit">
                          {"¿No tienes cuenta? Registrate aquí"}
                        </NavLink>
                        </Grid>
                        <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        >
                          Versión 1.0.1
                        </Grid>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="container"></div>
      </div>
    </>
  );
};

export default Login;
