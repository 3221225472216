import React from "react";
import "../../assets/css/contentpage/content_style.css";
import Footer from "../../components/MainPage/Footer";
import Header from "../../components/MainPage/Header";
const Menu = () => {
  return (
    <>
      <Header></Header>
      {/* CONTENEDOR PARA CUALQUIER PAGINA -> divcontainer y div-row */}
      <div id="container" className="container pt-10">
          <div className="row">
            ZINCRI
          </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default Menu;
