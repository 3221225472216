import {
  isNullOrUndefined,
  isEmptyOrInvalidString,
} from "../../utils/validations";

import swal from "sweetalert";

/**
 * 
 * *OBLIGATORIO* @param elements Lista de mensajes a mostrar, debe ser un array de strings
 * *OBLIGATORIO* @param title Titulo de la alerta
 * *OBLIGATORIO* @param icon Icono de la alerta
 * *OPCIONAL* @param buttons Botones y acciones en caso de ser requerido
 * *OPCIONAL* @param style Estilo extra para la alerta
 * @returns Una alerta swal con el contenido pasado en elements
 */
const CAlertList = ({ elements, title, icon, buttons, style }) => {
  if (
    isNullOrUndefined(elements) ||
    isEmptyOrInvalidString(title) ||
    isEmptyOrInvalidString(icon)
  ) {
    throw new Error(
      "CAlertList: Las propiedades 'elements', 'title' e 'icon' son obligatorios"
    );
  }
  const list = document.createElement("ul");
  elements.forEach((element) => {
    const listItem = document.createElement("li");
    listItem.innerHTML = element;
    list.appendChild(listItem);
  });

  swal({
    title: title,
    content: list,
    icon: icon,
    dangerMode: true,
    allowOutsideClick: false,
    closeOnEsc: false,
    buttons: buttons,
    style: style,
  });
};
export default CAlertList;
