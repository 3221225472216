import React, { useEffect } from "react";
import { Grid, TextField, Button, Box } from '@mui/material';
import { useFormik } from "formik";
import Yup from "../../utils/Yupi18n";
import { isEmptyOrInvalidString } from "../../utils/validations";

const Integrantes = ({ datos, send, activateButton, updateData, isComentario, reset, flagSubmit, isCorreo }) => {

    let initialValues = {};
    initialValues = datos ? (
        {
            TotalIntegrantes: datos.TotalIntegrantes ? datos.TotalIntegrantes : '',
            TotalMayores: datos.TotalMayores ? datos.TotalMayores : '',
            TotalMenores: datos.TotalMenores ? datos.TotalMenores : '',
        }) : (
            {
                TotalIntegrantes: '',
                TotalMayores: '',
                TotalMenores: '',
            }
        )

let validationSchema = Yup.object({
    TotalIntegrantes: Yup.number().label("Total Integrantes"),
    TotalMayores: Yup.number().label("Mayores de 18"),
    TotalMenores: Yup.number().label("Menores de 18")
});

const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
        send(values);
    }
});

function hanleOnSubmit(event) {
    formik.handleSubmit(event)

}
const hadleInputs = (event) => {
    updateData(event)
    formik.handleChange(event)
    //errors(formik.errors);
};

const handleOnBlur = (event) => {
    updateData(event)
    formik.handleBlur(event)
    //errors(formik.errors);
}

useEffect(() => {
    if (reset) {
        formik.resetForm({ values: '', touched: false })
    }
    // eslint-disable-next-line
}, [reset]);

useEffect(() => {
    if (flagSubmit) {
        hanleOnSubmit(formik.values)
        send(formik.values)
    }
    // eslint-disable-next-line
}, [flagSubmit]);

return (
    <div className="col-12 px-5 py-3">
        <Box
            sx={{
                "& > :not(style)": { m: 1, width: "30ch" },
            }}
        >
            <TextField
                required
                label="Total de Integrantes de la Familia"
                variant="standard"
                id="TotalIntegrantes"
                name="TotalIntegrantes"
                type="number"
                value={formik.values.TotalIntegrantes}
                onChange={hadleInputs}
                error={
                    formik.touched.TotalIntegrantes &&
                    !isEmptyOrInvalidString(formik.errors.TotalIntegrantes)
                }
                helperText={
                    formik.touched.TotalIntegrantes &&
                    formik.errors.TotalIntegrantes &&
                    formik.errors.TotalIntegrantes
                }
                onBlur={handleOnBlur}
            />
            <TextField
                label="Integrantes Mayores de 18"
                name="TotalMayores"
                variant="standard"
                id="TotalMayores"
                type="number"
                value={formik.values.TotalMayores}
                onChange={hadleInputs}
                error={
                    formik.touched.TotalMayores &&
                    !isEmptyOrInvalidString(formik.errors.TotalMayores)
                }
                helperText={
                    formik.touched.TotalMayores &&
                    formik.errors.TotalMayores &&
                    formik.errors.TotalMayores
                }
                onBlur={handleOnBlur}
            />
            <TextField
                label="Integrantes Menores de 18"
                name="TotalMenores"
                variant="standard"
                id="TotalMenores"
                type="number"
                value={formik.values.TotalMenores}
                onChange={hadleInputs}
                error={
                    formik.touched.TotalMenores &&
                    !isEmptyOrInvalidString(formik.errors.TotalMenores)
                }
                helperText={
                    formik.touched.TotalMenores &&
                    formik.errors.TotalMenores &&
                    formik.errors.TotalMenores
                }
                onBlur={handleOnBlur}
            />

        </Box>
        {
            activateButton ?
                <Grid conteiner sx={{ mt: 2 }} textAlign='right'>
                    <Button variant="contained" color="primary" type="button" style={{ borderRadius: "2em" }} round>
                        Guardar
                        </Button>
                </Grid> :
                <></>
        }
    </div>
);
}
export default Integrantes;