import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Container, Box } from "@mui/material";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";

import _ from "lodash";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import BarrasDobles from "../../components/Charts/AnalsisRevocacion/BarrasDobles";
import BarraMeta from "../../components/Charts/AnalsisRevocacion/BarraMeta";
import ComparativoRevocacion from "../../data/ComparativoRevocacion.json";
import Meta2024 from "../../data/municipios_lnom_meta24.json";
import Censo2020 from "../../data/municipios_censo2020.json";
import Militantes from "../../data/municipio_militantes.json";
import BloqueParidad from "../../data/bloques_paridad_cdm.json";
import MetaSeccion24 from "../../data/MetaSeccion24.json";
import Los100data from "../../data/Los100.json";
import L1data from "../../data/LN1.json";
import L2data from "../../data/LN2.json";
import L3data from "../../data/LN3.json";
import L4data from "../../data/LN4.json";

import {
  numberWithCommas,
  icoPartido,
  removeDiacritics,
} from "./../../utils/Functions";

import Notifications from "react-notify-toast";

import TimeLine from "../../components/Charts/TimeLine/index";
import resTendencia from "../../data/results_tendencia_2012_2021.json";
import resTendHistorico from "../../data/results_historicos_2012_2021.json";
import resHistorico from "../../data/results_historico_2012_2021.json";
import resParticipacion from "../../data/results_participacion_2015_2021.json";
import resSecciones from "../../data/results_seccioneslnom_2021.json";

import "./styles.css";

const Los100 = () => {
  const [isLoading, setLoading] = useState(false);
  const [dataLos100, setDataLos100] = useState([]);
  const [dataLista, setDataLista] = useState([]);
  const [dataL1, setDataL1] = useState([]);
  const [dataListaH, setDataListaH] = useState([]);
  const [dataListaM, setDataListaM] = useState([]);
  const [isPrintLoading, setPrintLoading] = useState(false);
  const [filtro, setFilter] = useState({ value: 1, label: "Abasolo" });
  const [availableFilters, setAvailableFilters] = useState([
    { value: null, label: "Seleccione" },
  ]);
  const [page1, setPage1] = useState(null);

  var isLine = true;

  useEffect(() => {
    setDataLos100(Los100data.data);

    const options = resHistorico.data.map((item) => {
      return { value: item.cve_mun, label: item.MUNICIPIO };
    });
    setAvailableFilters(
      options.concat([{ value: 47, label: "Comisión Permanente" }])
    );
  }, []);

  useEffect(() => {
    //_setmenu(getDataMenu(10, token.menus));
    setLoading(true);
    console.log("=>label value: ", filtro.value);

    var lista = [];
    switch (filtro.value) {
      case 1:
        lista = dataLos100.filter((item) => item.m1 !== null);
        setDataL1(L1data.data);
        break;
      case 2:
        lista = dataLos100.filter((item) => item.m2 !== null);
        setDataL1(L1data.data);
        break;
      case 3:
        lista = dataLos100.filter((item) => item.m3 !== null);
        setDataL1(L1data.data);
        break;
      case 4:
        lista = dataLos100.filter((item) => item.m4 !== null);
        setDataL1(L1data.data);
        break;
      case 5:
        lista = dataLos100.filter((item) => item.m5 !== null);
        setDataL1(L1data.data);
        break;
      case 6:
        lista = dataLos100.filter((item) => item.m6 !== null);
        setDataL1(L1data.data);
        break;
      case 7:
        lista = dataLos100.filter((item) => item.m7 !== null);
        setDataL1(L1data.data);
        break;
      case 8:
        lista = dataLos100.filter((item) => item.m8 !== null);
        setDataL1(L1data.data);
        break;
      case 9:
        lista = dataLos100.filter((item) => item.m9 !== null);
        setDataL1(L1data.data);
        break;
      case 10:
        lista = dataLos100.filter((item) => item.m10 !== null);
        setDataL1(L1data.data);
        break;
      case 11:
        lista = dataLos100.filter((item) => item.m11 !== null);
        setDataL1(L1data.data);
        break;
      case 12:
        lista = dataLos100.filter((item) => item.m12 !== null);
        setDataL1(L1data.data);
        break;
      case 13:
        lista = dataLos100.filter((item) => item.m13 !== null);
        setDataL1(L1data.data);
        break;
      case 14:
        lista = dataLos100.filter((item) => item.m14 !== null);
        setDataL1(L1data.data);
        break;
      case 15:
        lista = dataLos100.filter((item) => item.m15 !== null);
        setDataL1(L1data.data);
        break;
      case 16:
        lista = dataLos100.filter((item) => item.m16 !== null);
        setDataL1(L2data.data);
        break;
      case 17:
        lista = dataLos100.filter((item) => item.m17 !== null);
        setDataL1(L3data.data);
        break;
      case 18:
        lista = dataLos100.filter((item) => item.m18 !== null);
        setDataL1(L2data.data);
        break;
      case 19:
        lista = dataLos100.filter((item) => item.m19 !== null);
        setDataL1(L2data.data);
        break;
      case 20:
        lista = dataLos100.filter((item) => item.m20 !== null);
        setDataL1(L2data.data);
        break;
      case 21:
        lista = dataLos100.filter((item) => item.m21 !== null);
        setDataL1(L2data.data);
        break;
      case 22:
        lista = dataLos100.filter((item) => item.m22 !== null);
        setDataL1(L2data.data);
        break;
      case 23:
        lista = dataLos100.filter((item) => item.m23 !== null);
        setDataL1(L2data.data);
        break;
      case 24:
        lista = dataLos100.filter((item) => item.m24 !== null);
        setDataL1(L2data.data);
        break;
      case 25:
        lista = dataLos100.filter((item) => item.m25 !== null);
        setDataL1(L2data.data);
        break;
      case 26:
        lista = dataLos100.filter((item) => item.m26 !== null);
        setDataL1(L2data.data);
        break;
      case 27:
        lista = dataLos100.filter((item) => item.m27 !== null);
        setDataL1(L2data.data);
        break;
      case 28:
        lista = dataLos100.filter((item) => item.m28 !== null);
        setDataL1(L2data.data);
        break;
      case 29:
        lista = dataLos100.filter((item) => item.m29 !== null);
        setDataL1(L2data.data);
        break;
      case 30:
        lista = dataLos100.filter((item) => item.m30 !== null);
        setDataL1(L2data.data);
        break;
      case 31:
        lista = dataLos100.filter((item) => item.m31 !== null);
        setDataL1(L3data.data);
        break;
      case 32:
        lista = dataLos100.filter((item) => item.m32 !== null);
        setDataL1(L3data.data);
        break;
      case 33:
        lista = dataLos100.filter((item) => item.m33 !== null);
        setDataL1(L3data.data);
        break;
      case 34:
        lista = dataLos100.filter((item) => item.m34 !== null);
        setDataL1(L3data.data);
        break;
      case 35:
        lista = dataLos100.filter((item) => item.m35 !== null);
        setDataL1(L3data.data);
        break;
      case 36:
        lista = dataLos100.filter((item) => item.m36 !== null);
        setDataL1(L3data.data);
        break;
      case 37:
        lista = dataLos100.filter((item) => item.m37 !== null);
        setDataL1(L3data.data);
        break;
      case 38:
        lista = dataLos100.filter((item) => item.m38 !== null);
        setDataL1(L3data.data);
        break;
      case 39:
        lista = dataLos100.filter((item) => item.m39 !== null);
        setDataL1(L3data.data);
        break;
      case 40:
        lista = dataLos100.filter((item) => item.m40 !== null);
        setDataL1(L3data.data);
        break;
      case 41:
        lista = dataLos100.filter((item) => item.m41 !== null);
        setDataL1(L3data.data);
        break;
      case 42:
        lista = dataLos100.filter((item) => item.m42 !== null);
        setDataL1(L3data.data);
        break;
      case 43:
        lista = dataLos100.filter((item) => item.m43 !== null);
        setDataL1(L3data.data);
        break;
      case 44:
        lista = dataLos100.filter((item) => item.m44 !== null);
        setDataL1(L3data.data);
        break;
      case 45:
        lista = dataLos100.filter((item) => item.m45 !== null);
        setDataL1(L3data.data);
        break;
      case 46:
        lista = dataLos100.filter((item) => item.m46 !== null);
        setDataL1(L2data.data);
        break;
      case 47:
        lista = dataLos100.filter((item) => item.m47 === "1");
        setDataL1(L4data.data);
        console.log("=>Ver coimision Permanente: ", lista);
        break;
      default:
        break;
    }

    var Hombres = lista.filter((p) => p.Sexo === "H");
    var Mujeres = lista.filter((p) => p.Sexo === "M");

    console.log("=>L hombres: ", Hombres);
    console.log("=>L Mujeres: ", Mujeres);

    var ListaFinal = [];
    if (Hombres.length) {
      for (var x = 0; x < 25; x++) {
        const itemH = Hombres[x];
        const itemM = Mujeres[x];
        console.log("=>L itemH", itemH);
        console.log("=>L itemM", itemM);
        ListaFinal.push({
          idMunicipio: filtro.value,
          Hombre: Hombres[x].militante,
          Mujer: Mujeres[x].militante,
        });
      }

      setDataListaH(lista.filter((p) => p.Sexo === "H"));
      setDataListaM(lista.filter((p) => p.Sexo === "M"));
    } else {
      console.log("=>No hay hombres");
    }

    setDataLista(ListaFinal);

    setLoading(false);
  }, [filtro]);

  useEffect(() => {
    console.log("=>LHombres: ", dataListaH);
    console.log("=>LMujeres: ", dataListaM);
  }, [dataListaH, dataListaM]);

  useEffect(() => {
    if (page1 !== null) {
    }
  }, [page1, filtro]);

  const sendResults = (item) => {
    //setshowMap(false);
    setFilter(item);
    console.log("=>item: ", item);
  };

  const printDocument = () => {
    setPrintLoading(true);
    console.log("=>Begin Print");
    const divpage1 = document.getElementById("divToPrint1");

    html2canvas(divpage1).then((canvas) => {
      //const imgData = canvas.toDataURL("image/png");
      setPage1(canvas);
      console.log("=>Ready Page1");
    });
  };

  return (
    <>
      <Notifications></Notifications>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm="12" md="12" lg="12" className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                <CardContent className="p-0">
                  <Container>
                    <Box>
                      <Select
                        options={availableFilters}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={filtro}
                        onChange={(e) => sendResults(e)}
                        placeholder="Seleccione una opción"
                        isLoading={isLoading}
                      />
                      {!isPrintLoading ? (
                        <button onClick={() => printDocument()}>
                          Exportar
                        </button>
                      ) : (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#0b3cae"
                          loading={true}
                        />
                      )}
                      <br />

                      <div
                        id="divToPrint1"
                        style={{ paddingLeft: "5px", paddingTop: "10px" }}
                      >
                        <Grid item xs={12} md={12} lg={12}>
                          {!isLoading ? (
                            <>
                              <table style={{ width: "1000px" }}>
                                <tr>
                                  <td>
                                    <table
                                      id="historico"
                                      style={{ width: "750px" }}
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            "text-align": "center",
                                            "background-color": "#E4008C",
                                          }}
                                        >
                                          Mujeres
                                        </th>
                                        <th
                                          style={{
                                            "text-align": "center",
                                            "background-color": "#FFFFFF",
                                          }}
                                        ></th>
                                        <th
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          Hombres
                                        </th>
                                      </tr>
                                      {dataLista.length > 0 &&
                                        dataLista.map((item, index) => {
                                          isLine = isLine ? false : true;
                                          return (
                                            <tr key={index}>
                                              <td
                                                className={isLine ? "si" : "no"}
                                              >
                                                {item.Mujer}
                                              </td>
                                              <td
                                                style={{
                                                  "text-align": "center",
                                                  "background-color": "#FFFFFF",
                                                }}
                                              ></td>
                                              <td
                                                className={isLine ? "si" : "no"}
                                              >
                                                {item.Hombre}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </table>
                                  </td>
                                  <td
                                    className="top"
                                    style={{ marginLeft: 10 }}
                                  >
                                    <table
                                      id="historico"
                                      style={{ width: "300px", marginLeft: 40 }}
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            textAlign: "center",
                                          }}
                                        >
                                          Nacional
                                        </th>
                                      </tr>
                                      {dataL1.length > 0 &&
                                        dataL1.map((item, index) => {
                                          isLine = isLine ? false : true;
                                          return (
                                            <tr key={index}>
                                              <td
                                                className={isLine ? "no" : "si"}
                                              >
                                                {item.Nacional}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                    </Box>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Los100;
