import React, { useState, useEffect } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircleSpinner } from "react-spinners-kit";

const BarrasDobles = ({ dataInfo }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log("=>Update Dona: ", dataInfo);
    setData({
      chart: {
        type: "column",
      },
      title: {
        text: `SIAN vs Pase de Lista de ${dataInfo.text}`,
      },
      xAxis: {
        categories: [
          "Compromisos22",
          "Compromisos21",
          "Promoción21",
          "Electoral21",
          "Militantes",
          "Funcionarios",
        ],
      },
      yAxis: [
        {
          title: {
            text: "Compromisos",
          },
          opposite: true,
        },
      ],
      legend: {
        enabled: true,
        verticalAlign: "top",
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
        column: {
          dataLabels: {
            enabled: true,
          },
          grouping: false,
          shadow: false,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Compromisos",
          color: "rgb(8,49,142)",
          data: dataInfo.Compromisos,
          pointPadding: 0.3,
          pointPlacement: -0.2,
        },
        {
          name: "Pase Lista",
          color: "rgb(97,153,207)",
          data: dataInfo.Asistencia,
          pointPadding: 0.4,
          pointPlacement: -0.2,
        },
      ],
    });
  }, [dataInfo]);

  return data !== null ? (
    <HighchartsReact highcharts={Highcharts} options={data} type="column" />
  ) : (
    <CircleSpinner
      className="text-center"
      size={30}
      color="#686769"
      loading={true}
    />
  );
};

export default BarrasDobles;
