import React,{useState} from "react";
import {
  Grid
  , Card
  , Box
  , Paper
  , Button
  , Divider
  , Container
  , CardContent
} from "@mui/material";
import Icon from '@mui/material/Icon';
import { getDataMenu } from '../../data/menus';
import { getVars } from "../../utils/GlobalStorage";
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import CardTotal from "../../components/Comite/Box"
import Tablacomite from "../../components/Comite/TablaComite";

const ComiteSinSolicitud = () => {
	const token = getVars("Token");
  const [_menu, _setmenu] = useState({});

  const columns = [
    { id: "Folio", label: "Folio" },
    { id: "Fechasolicitud", label: "Fecha Solicitud" },
    { id: "Curp", label: "Curp" },
    { id: "NombreCompleto", label: "Nombre Completo" },
    { id: "Programa", label: "Programa social" },
    { id: "Remesa", label: "Remesa" },
    { id: "Estatus", label: "Estatus" }
  ];

  const handleChangePagination = (e) => {
  };

  const Rows = []

  return (
    <>
      <Header></Header>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm="12" md="12" lg="12" className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                <CardContent className="p-0">
                  <Container>
                    <Box >
                      <Button
                        href="#/comites"
                        sx={{ m: 3, maxWidth: 120 }}
                        variant="outlined"
                        size="small"
                        startIcon={<Icon>arrow_back</Icon>}
                      >
                        Regresar
                      </Button>
                      <Divider
                        sx={{ mb: 3 }}
                      />
                      <Grid container >
                        <Grid item xs={12} md={12} lg={12}>
                          <Tablacomite
													  isPermisoAgregar={_menu.Agregar === 1}
                            rows={Rows}
														Titulo="Listado Solicitudes" 
                            link="#/captura_comites_solicitudes" 
                            hcolumns={columns}
                            rowCount={Rows}
                            pageSize={5}
                            pageProp={1}
                            Dato="Emergencias"
                            handleChangePagination={(e) =>
                              handleChangePagination(e)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </>
  );
};
export default ComiteSinSolicitud;
