import React, { useEffect } from "react";
import { Grid, TextField, Button, Box, Stack } from '@mui/material';
import { useFormik } from "formik";
import Yup from "../../utils/Yupi18n";
import { isEmptyOrInvalidString } from "../../utils/validations";

const Contactos = ({ datos, send, activateButton, updateData, isComentario, reset, flagSubmit, isCorreo }) => {

    let initialValues = {};
    initialValues = datos ? (
        {
            TelCel: datos.Usuario ? datos.Usuario : '',
            TelFijo: datos.TelFijo ? datos.TelFijo : '',
            TelRecados: datos.TelRecados ? datos.TelRecados : '',
            Correo: datos.Correo ? datos.Correo : '',
            Comentario: datos.Comentario ? datos.Comentario : ''
        }) : (
            {
                TelCel: '',
                TelFijo: '',
                TelRecados: '',
                Correo: '',
                Comentario: ''
            }
        )

let validationSchema = Yup.object({
    TelCel: Yup.string().required().label("Celular"),
    TelFijo: Yup.string().max(10).label("Telefono"),
    TelRecados: Yup.string().max(10).label("Telefono Recado"),
    Correo: Yup.string().email().label("Correo"),
    Comentario: Yup.string().label("Comentario")
});

const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
        send(values);
    }
});

function hanleOnSubmit(event) {
    formik.handleSubmit(event)

}
const hadleInputs = (event) => {
    updateData(event)
    formik.handleChange(event)
    //errors(formik.errors);
};

const handleOnBlur = (event) => {
    updateData(event)
    formik.handleBlur(event)
    //errors(formik.errors);
}

useEffect(() => {
    if (reset) {
        formik.resetForm({ values: '', touched: false })
    }
    // eslint-disable-next-line
}, [reset]);

useEffect(() => {
    if (flagSubmit) {
        hanleOnSubmit(formik.values)
        send(formik.values)
    }
    // eslint-disable-next-line
}, [flagSubmit]);

return (
    <div className="col-12 px-5 py-3">
        <Box
            sx={{
                "& > :not(style)": { m: 1, width: "30ch" },
            }}
        >
            <TextField
                required
                label="Celular"
                variant="standard"
                id="TelCel"
                name="TelCel"
                inputProps={{
                    maxLength: 10,
                    pattern: "[0-9]"
                }}
                type="text"
                value={formik.values.TelCel}
                onChange={
                    (e) => {
                        const pattern = e.target.value.match(/[a-zA-Z]/g);
                        if (!pattern) {
                            hadleInputs(e)
                        }
                    }
                }
                error={
                    formik.touched.TelCel &&
                    !isEmptyOrInvalidString(formik.errors.TelCel)
                }
                helperText={
                    formik.touched.TelCel &&
                    formik.errors.TelCel &&
                    formik.errors.TelCel
                }
                onBlur={handleOnBlur}
            />
            <TextField
                label="Telefono Fijo"
                name="TelFijo"
                variant="standard"
                id="TelFijo"
                type="text"
                value={formik.values.TelFijo}
                onChange={
                    (e) => {
                        const pattern = e.target.value.match(/[a-zA-Z]/g);
                        if (!pattern) {
                            hadleInputs(e)
                        }
                    }
                }
                error={
                    formik.touched.TelFijo &&
                    !isEmptyOrInvalidString(formik.errors.TelFijo)
                }
                helperText={
                    formik.touched.TelFijo &&
                    formik.errors.TelFijo &&
                    formik.errors.TelFijo
                }
                onBlur={handleOnBlur}
            />
            <TextField
                label="Telefono de Recados"
                name="TelRecados"
                variant="standard"
                id="TelRecados"
                type="text"
                value={formik.values.TelRecados}
                onChange={
                    (e) => {
                        const pattern = e.target.value.match(/[a-zA-Z]/g);
                        if (!pattern) {
                            hadleInputs(e)
                        }
                    }
                }
                error={
                    formik.touched.TelRecados &&
                    !isEmptyOrInvalidString(formik.errors.TelRecados)
                }
                helperText={
                    formik.touched.TelRecados &&
                    formik.errors.TelRecados &&
                    formik.errors.TelRecados
                }
                onBlur={handleOnBlur}
            />
            {
                isCorreo ? (
                    <TextField
                        label="Correo"
                        name="Correo"
                        variant="standard"
                        id="Correo"
                        type="email"
                        value={formik.values.Correo}
                        onChange={hadleInputs}
                        error={
                            formik.touched.Correo &&
                            !isEmptyOrInvalidString(formik.errors.Correo)
                        }
                        helperText={
                            formik.touched.Correo &&
                            formik.errors.Correo &&
                            formik.errors.Correo
                        }
                        onBlur={handleOnBlur}
                    />
                ) : (
                        <></>
                    )
            }

        </Box>
        {

            isComentario ? (
                <Stack direction="row" sx={{
                    "& > :not(style)": { m: 1, width: "100ch" },
                }}>
                    <TextField
                        label="Comentario"
                        name="Comentario"
                        multiline
                        rows={3}
                        id="Comentario"
                        type="email"
                        value={formik.values.Comentario}
                        onChange={hadleInputs}
                        error={
                            formik.touched.Comentario &&
                            !isEmptyOrInvalidString(formik.errors.Comentario)
                        }
                        helperText={
                            formik.touched.Comentario &&
                            formik.errors.Comentario &&
                            formik.errors.Comentario
                        }
                        onBlur={handleOnBlur}
                    />
                </Stack>
            ) : (<></>)
        }
        {
            activateButton ?
                <Grid conteiner sx={{ mt: 2 }} textAlign='right'>
                    <Button variant="contained" color="primary" type="button" style={{ borderRadius: "2em" }} round>
                        Guardar
                        </Button>
                </Grid> :
                <></>
        }
    </div>
);
}
export default Contactos;