import React, { useState, useEffect } from "react";
import {
  Grid
  , Card
  , Container
  , Button
  , Divider
  , Box
} from "@mui/material";
import Icon from '@mui/material/Icon';
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import FormComite from "../../components/Comite/DatosComite";
import NotPermisos from "../../components/Comite/AlertNotPermiso";

import swal from "sweetalert";
import ApiExec from "../../utils/ApiExec";
import { getVars } from "./../../utils/GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import { getDataMenu } from './../../data/menus';

const ComiteAddSolicitud = () => {
  const token = getVars("Token");
  const [_menu, _setmenu] = useState({});
  const [Arraycomite, setArraycomite] = useState([]);
  const [isVisible, setisVisible] = useState(false);

  useEffect(() => {
    _setmenu(getDataMenu(11, token.menus))
  }, []);

  const handleSave = (array) => {
    var dato = {
      idPrograma: array.idPrograma,
      Fecha: array.Fecha,
      Leyenda: array.Leyenda,
      idTipo: array.idTipo
    }

    ApiExec(dato, "setComite", token.access_token, {})
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setArraycomite(
            [...Arraycomite,
            res.data]
          )
          swal({
            title: res.message,
            icon: "success",
            dangerMode: true,
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                className: "btn-success",
                closeModal: true
              }
            }
          })
          setisVisible(true);
        } else {
          swal({
            title: "Generado Exitosamente",
            icon: "error",
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                className: "btn-warning",
                closeModal: true
              }
            }
          })
        }
      })
      .catch((e) => {
        notify.show(`Error setComite! ${e.message}`, "custom", 5000, {
          background: "#0E1717",
          text: "#FFFFFF",
        });
      });
  }

  return (
    <>
      <Header></Header>
      <Notifications></Notifications>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm="12" md="12" lg="12" className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                {_menu.Agregar === 1 ? (
                  <>
                    <Box >
                      <Button
                        href="#/comites"
                        sx={{ m: 3, maxWidth: 120, }}
                        variant="outlined"
                        size="small"
                        startIcon={<Icon>arrow_back</Icon>}
                      >
                        Regresar
                      </Button>
                      <Divider
                        sx={{ mb: 5 }}
                      />
                    </Box>

                  </>
                ) : (
                  <NotPermisos />
                )
                }
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </>
  );
};
export default ComiteAddSolicitud;
