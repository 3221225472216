import React, { useEffect, useState } from "react";
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import { Stack, Card, Container } from "@mui/material";
import SquareIcon from '../../components/Cards/SquareIcon';
import { Home, Restore, Description, Task, RuleFolder } from '@mui/icons-material';
import GTable from "./../../components/MaterialTable/GTable ";
import { getVars } from "../../utils/GlobalStorage";
import { getDataMenu } from './../../data/menus';
import ApiExec from "../../utils/ApiExec";
import Notifications, { notify } from "react-notify-toast";

const ListCenso = () => {
    const token = getVars("Token");
    const [_menu, _setmenu] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const tableData = (filtro) => {
        ApiExec(filtro, "getSolicitudes", token.access_token, {})
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setData(res.data)
                    onSubmitComponents(false)
                } else {
                    onSubmitComponents(false)
                    notify.show(
                        `Error getSolicitudes: ${res.message}`,
                        "error",
                        5000,
                        "#FFFFFF"
                    );
                }
            })
            .catch((e) => {
                onSubmitComponents(false)
                notify.show(`Error getSolicitudes! ${e.message}`, "custom", 5000, {
                    background: "#0E1717",
                    text: "#FFFFFF",
                });
            });
    //Apis Cards

        };

    useEffect(() => {
        _setmenu(getDataMenu(7, token.menus))
        tableData({})
        console.log(_menu)
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            id: 'Folio',
            numeric: false,
            disablePadding: true,
            label: 'Folio',
        },
        {
            id: 'FechaSolicitud',
            numeric: false,
            disablePadding: true,
            label: 'Fecha de Solicitud',
        },
        {
            id: 'Curp',
            numeric: true,
            disablePadding: false,
            label: 'Curp',
        },
        {
            id: 'NombreCompleto',
            numeric: true,
            disablePadding: false,
            label: 'Nombre',
        },
        {
            id: 'Programa',
            numeric: true,
            disablePadding: false,
            label: 'Programa',
        },
        {
            id: 'Remesa',
            numeric: true,
            disablePadding: false,
            label: 'Remesa',
        },
        {
            id: 'Status',
            numeric: true,
            disablePadding: false,
            label: 'Estatus',
        },
        {
            id: 'Actions',
            numeric: true,
            disablePadding: false,
            label: '',
        },
    ];

    const handleChangePagination = (e) => {
        console.log(e)
    };

    async function onSubmitComponents(data) {
        let res = await setTimeout(function () { return true }, 100);
        if (res) {
            setLoading(data);
        }
    }

   


    return (
        <>
        <Notifications />
            <Header></Header>
            <Container id="container" className="pt-10">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 pr-0">
                        <div className="pb-4">
                            <div className="container p-0">
                                <div className="card">
                                    <div className="card-body p-0"></div>

                                    <Stack conteiner>
                                        <Stack direction="row" sx={{
                                            "& > :not(style)": { m: 1, width: "50ch" },
                                        }}>
                                            <SquareIcon
                                                ColorLeft="#094DD3"
                                                IconFooder={<Restore fontSize="18" />}
                                                IconHeader={<Description sx={{ fontSize: 40 }} />}
                                                percentageLeft="95%"
                                                percentageRigth="20%"
                                                title="Solicitudes"
                                                ColorRigth="#76A4F5"
                                                data="1220"
                                                dataFooder="Fecha"
                                            />
                                            <SquareIcon
                                                ColorLeft="#1ACE3B"
                                                ColorRigth="#76A4F5"
                                                IconFooder={<Restore fontSize="18" />}
                                                IconHeader={<Task sx={{ fontSize: 40 }} />}
                                                percentageLeft="95%"
                                                percentageRigth="20%"
                                                title="Aprobados"
                                                data="2220"
                                                dataFooder="Fecha"
                                            />
                                            <SquareIcon
                                                ColorLeft="#EC33FF"
                                                ColorRigth="#76A4F5"
                                                IconFooder={<Restore fontSize="18" />}
                                                IconHeader={<RuleFolder sx={{ fontSize: 40 }} />}
                                                percentageLeft="95%"
                                                percentageRigth="20%"
                                                title="Por Aprobar"
                                                data="2"
                                                dataFooder="Fecha"
                                            />
                                            <SquareIcon
                                                ColorLeft="#FF33CE"
                                                ColorRigth="#D5ECFF"
                                                IconFooder={<Restore fontSize="18" />}
                                                IconHeader={<Home sx={{ fontSize: 40 }} />}
                                                percentageLeft="95%"
                                                percentageRigth="20%"
                                                title="Sin remesa"
                                                data="2"
                                                dataFooder="Fecha"
                                            />
                                        </Stack>
                                        <Stack >
                                            <Card>
                                                {
                                                    loading?(
                                                        <>Loading..</>
                                                    ):(
                                                    <GTable data={data} hcolumns={columns} rowCount={data}
                                                    pageSize={5} pageProp={1} Dato="Emergencias" handleChangePagination={(e) => handleChangePagination(e)} />
                                                    )
                                                }
                                                
                                            </Card>
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ Container>
            <Footer></Footer>
        </>
    );
}
export default ListCenso;