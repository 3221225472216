import React from "react";
//import Logo from "../../assets/SIAN_Transparente.png";
import Logo from "../../assets/CDE.png";

const Navbar = () => {
  return (
    <>
      <div id="nav">
        <nav
          id="nav-top-second"
          className="navbar fixed-top navbar-toggleable-md navbar-dark scrolling-navbar mt-2 p-0"
          style={{ backgroundColor: "#000f9f" }}
        >
          <div className="container">
            <button
              className="pull-right m-0 btn btn-lg navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ height: "100%" }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand p-1 m-0" href="/">
              <img
                src={Logo}
                className="d-inline-block align-top wow fadeInLeft img-fluid"
                data-wow-delay="0.6s"
                alt="SIAN"
                style={{ maxWidth: "50px", borderRadius: "20px!important" }}
              />
            </a>
          </div>
        </nav>
      </div>
    </>
  );
};
export default Navbar;
