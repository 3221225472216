import React, { useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  Select as SelectLibrery,
  FormControl,
  FormHelperText
} from "@mui/material";
import ApiExec from "../../utils/ApiExec";
import { getVars } from "./../../utils/GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import { MetroSpinner } from "react-spinners-kit";

const Select = ({ title, options, fullWidth, onChange, name, api, params = {},required, _selected, error, helperText, onBlur, multiple }) => {
  const token = getVars("Token");
  const _title = title !== undefined ? title : "Sin título";
  const [_options, setOptions] = useState(Array.isArray(options)
    ? options
    : [{ value: 0, label: "Selecciona una opción" }]);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e) => {
    onChange(e);
  };

  const catalogos = () => {
    ApiExec(params, api, token.access_token, {})
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setOptions(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          notify.show(
            `Error de registro de su usuario: ${res.message}`,
            "error",
            5000,
            "#FFFFFF"
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error setUsuario! ${e.message}`, "custom", 5000, {
          background: "#0E1717",
          text: "#FFFFFF",
        });
      });

  }

  useEffect(() => {

  }, [_selected]);

  useEffect(() => {
    if (api) catalogos()
    // eslint-disable-next-line
  }, [title, options]);

  return (
    <>
    {
      loading?
      (<MetroSpinner
        size={30}
        color="#2285E7"
        loading={loading}
      />)
      :(
        <FormControl variant="standard" sx={{width:"100%"}}>
        <Notifications />
        <InputLabel id={"_title" + _title}>{_title}</InputLabel>
        <SelectLibrery
          required={required}
          fullWidth={fullWidth}
          style={{ height: "4.9ch", m: 0 }}
          labelId={"_title" + _title}
          id={"_title1" + _title}
          value={_selected}
          label={"_title1" + _title}
          multiple={multiple}
          name={name}
          error={error}
          onChange={handleOnChange}
          onBlur={onBlur}
          placeholder="Seleccione"
        >
          {_options.map((item) => (
            <MenuItem value={item.value} key={`${item.value}`}>{item.label}</MenuItem>
          ))}
        </SelectLibrery>
        {
          helperText?<FormHelperText sx={{color:"red"}}>{helperText}</FormHelperText>:<></>
        }
        
      </FormControl>
   
      )
    }
    </>
  );
};
export default Select;
