import { useState, useEffect } from "react";
import { Box } from "@mui/material";

const Timer = ({ fecha }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Calcula el tiempo restante
    const targetDate = new Date(fecha);
    const now = new Date();

    const timeDiff = targetDate - now;

    if (timeDiff > 0) {
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setTimeRemaining({ days, hours, minutes, seconds });

      // Actualiza la cuenta regresiva cada segundo
      const countdownInterval = setInterval(() => {
        if (timeDiff > 0) {
          const updatedDiff = targetDate - new Date();
          const updatedDays = Math.floor(updatedDiff / (1000 * 60 * 60 * 24));
          const updatedHours = Math.floor((updatedDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const updatedMinutes = Math.floor((updatedDiff % (1000 * 60 * 60)) / (1000 * 60));
          const updatedSeconds = Math.floor((updatedDiff % (1000 * 60)) / 1000);

          setTimeRemaining({
            days: updatedDays,
            hours: updatedHours,
            minutes: updatedMinutes,
            seconds: updatedSeconds,
          });
        } else clearInterval(countdownInterval);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }

    // eslint-disable-next-line
  }, []);

  const flex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    marginTop: "-1.5rem",
  };

  const fontSize = 60;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "55%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        px: 2,
      }}
    >
      <Box
        sx={{
          fontSize: fontSize,
          color: "white",
          ...flex,
          marginTop: "0",
          flexWrap: "wrap",
          fontFamily: "FuturaStd_4, sans-serif",
        }}
      >
        <Box sx={flex}>
          <Item text="Faltan" />
        </Box>
        <Box sx={flex}>
          <Item text={timeRemaining?.days} fontWeight={900} fontSize={100} />
          <Item text="días" />
        </Box>
        <Box sx={flex}>
          <Item text={timeRemaining?.hours} fontWeight={900} fontSize={100} />
          <Item text="horas" />
        </Box>
        <Box sx={flex}>
          <Item text={timeRemaining?.minutes} fontWeight={900} fontSize={100} />
          <Item text="min y" />
        </Box>
        <Box sx={flex}>
          <Item text={timeRemaining?.seconds} fontWeight={900} fontSize={100} />
        </Box>
      </Box>
    </Box>
  );
};

const Item = ({ text, fontWeight = 500, fontSize = "auto", width = "auto" }) => (
  <Box component="span" sx={{ fontWeight, fontSize, width: width, whiteSpace: "nowrap" }}>
    {text}
  </Box>
);

export default Timer;
