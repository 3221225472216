
export const getDate = ({fecha=null}) => {
    let date;
    if (!fecha) {
        date = new Date();
        date = date.toISOString().split('T')[0] + ' '
            + date.toTimeString().split(' ')[0];
    } else {
        date = new Date(fecha);
        //date = date.toLocaleString('en-US',{timeZone: "America/Mexico_City"});
        date = date.toISOString().split('T')[0] + ' '
        + date.toTimeString().split(' ')[0];
    }
    return date;
};

