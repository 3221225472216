import React, { useEffect } from "react";
import {
	TextField
	, Button
	, Box
	, FormControl
	, RadioGroup
	, FormControlLabel
	, Radio
	, InputAdornment
	, Typography
	, FormLabel
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { useFormik } from "formik";
import Select from "../Select/Select";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { getDate } from "../../utils/AuxiliarFunction";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { isEmptyOrInvalidString } from "../../utils/validations";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ComiteAddSchema } from "../../data/schemas/ComiteSchemas"
import { ComiteAddInterface } from "../../data/interfaces/ComiteInterfaces";


const FormDatosComite = (props) => {
	const { 
		send, 
		Comite, 
		isVisible ,
	}= props

	let initialValues = ComiteAddInterface;

	let validationSchema = ComiteAddSchema;

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			send(values);
		}
	});

	useEffect(() => {
	}, [Comite]);

	return (
		<div
			className="col-8 px-5 py-3"
			style={{
				textAlign: 'center',
				margin: "auto",
				width: 1200,
				height: 450
			}}
		>
				<Typography
					sx={{
						fontWeight: 'light',
						fontFamily: 'Helvetica Neue',
						color: 'GrayText',
						textAlign: 'center',
						mb: 3
					}}> Captura de Acta
				</Typography>
				<Box
					overflow="auto"
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{
						"& > :not(style)": { m: 1, width: "30ch" },
						backgroundColor: (theme) =>
							theme.palette.mode === 'light'
								? theme.palette.grey[200]
								: theme.palette.grey[1200],
						flexGrow: 1,
						height: '35vh',
					}}
				>
					<Select
						error={
							formik.touched.idPrograma &&
							!isEmptyOrInvalidString(formik.errors.idPrograma)
						}
						required={true}
						fullWidth={true}
						title="Programa Social"
						api="getProgramasSociales"
						name="idPrograma"
						_selected={formik.values.idPrograma}
						onChange={formik.handleChange}
						helperText={
							formik.touched.idPrograma &&
							formik.errors.idPrograma &&
							formik.errors.idPrograma
						}
						onBlur={formik.handleBlur}
					/>
					<TextField
						required
						label="Leyenda"
						name="Leyenda"
						variant="standard"
						id="Leyenda"
						type="text"
						onChange={formik.handleChange}
						value={formik.values.Leyenda}
						error={
							formik.touched.Leyenda &&
							!isEmptyOrInvalidString(formik.errors.Leyenda)
						}
						helperText={
							formik.touched.Leyenda &&
							formik.errors.Leyenda &&
							formik.errors.Leyenda
						}
						onBlur={formik.handleBlur}
					/>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							error={
								formik.touched.Fecha &&
								!isEmptyOrInvalidString(formik.errors.Fecha)
							}
							required={true}
							label="Fecha"
							name="Fecha"
							inputFormat="dd/MM/yyyy"
							format="dd/MM/yyyy"
							value={formik.values.Fecha}
							onChange={(e) => {
								let fecha = getDate({ fecha: e })
								formik.handleChange({ target: { name: "Fecha", value: fecha } });
							}}
							helperText={
								formik.touched.Fecha &&
								formik.errors.Fecha &&
								formik.errors.Fecha
							}
							onBlur={formik.handleBlur}
							renderInput={(params) => (
								<TextField variant="standard" {...params} />
							)}
						/>
					</LocalizationProvider>
					<FormControl sx={{ minWidth: 300 }} >
						<FormLabel component="legend" sx={{ textAlign: 'center' }}>Tipo</FormLabel>
						<RadioGroup
							row aria-label="idTipo"
							name="idTipo"
							defaultValue="top"
							value={formik.values.idTipo}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						>
							<FormControlLabel
								value="1"
								control={<Radio color="secondary" size="small" />}
								label="Extraordinario"
								labelPlacement="start"
							/>
							<FormControlLabel
								value="2"
								control={<Radio color="secondary" size="small" />}
								label="Ordinario"
								labelPlacement="start"
							/>
						</RadioGroup>
					</FormControl>
					<Box
						style={{ textAlign: 'right', margin: "auto", width: 550 }}
						sx={{
							"& > :not(style)": { m: 1, width: "70ch", position: "relative" },
						}}
					>
						<Button
							sx={{ maxWidth: 180 }}
							type="submit"
							size="sm"
							variant="outlined"
							color="success"
							startIcon={<Icon>ads_click</Icon>}
						>
							Generar Acta
						</Button>
					</Box>
				</Box>
				{isVisible && (
				<Box
					component="form"
					sx={{
						"& > :not(style)": { m: 1, position: "relative" },
					}}
				>
					<TextField
						sx={{ minWidth: 300 }}
						size="small"
						id="Acta"
						label="Nº de Acta"
						name="Acta"
						type="text"
						value={Comite[0].Acta}
						InputProps={{
							readOnly: true,
							size: 'small',
							startAdornment: (
								<InputAdornment position="start">
									<Icon>texture</Icon>
								</InputAdornment>
							),
						}}
						variant='filled'
					/>
					<TextField
						sx={{ minWidth: 300 }}
						size="small"
						id="Ejercicio"
						label="Ejercicio"
						name="Ejercicio"
						type="text"
						value={Comite[0].Ejercicio}
						InputProps={{
							readOnly: true,
							size: 'small',
							startAdornment: (
								<InputAdornment position="start">
									<Icon>event_available</Icon>
								</InputAdornment>
							),
						}}
						variant="filled"
					/>
				</Box>
			)}
		</div>
	);
}
export default FormDatosComite;