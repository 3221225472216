import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const TimeLine = ({ title, subtitle, yAxis, xAxis, series }) => {
  const Options = {
    title: {
      text: title,
      height: "120px",
    },

    subtitle: {
      text: subtitle || "",
    },
    colors: [
      "#00579D",
      "#E12314",
      "#D3B420",
      "#FF4B88",
      "#51B948",
      "#FF8300",
      "#9E3848",
    ],
    yAxis,

    xAxis,
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: true,
      },
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={Options} type="column" />
  );
};

export default TimeLine;
