import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import "./index.css";
import "react-datetime/css/react-datetime.css";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import NotFound from "./pages/Auth/NotFound";
import Menu from "./pages/Menu/Menu";
//import Inicio from "./pages/Inicio/Inicio";
import Home from "./pages/Home/Home";
import Comite from "./pages/Comite/Comite.jsx";
import ComiteAdd from "./pages/Comite/ComiteAdd.jsx";
import ComiteSolicitud from "./pages/Comite/ComiteSolicitud.jsx";
import ComiteSinSolicitud from "./pages/Comite/ComiteSinSolicitud.jsx";
import ComiteAddSolicitud from "./pages/Comite/ComiteAddSolicitud.jsx";
import CurpAccess from "./pages/Questionary/CurpAccess";
import Users from "./pages/Users/Users.jsx";
import AddSolicitud from "./pages/Solicitudes/AddSolicitud";
import ListSolicitud from "./pages/Solicitudes/ListSolicitud";
import EditSolicitud from "./pages/Solicitudes/EditSolicitud";
import Reconocimientos from "./pages/Home/Reconocimientos";
import Analisis from "./pages/analisis/Analisis.jsx";
//import Activacion4Dic from "./pages/analisis/Activacion4Dic.jsx";
import Activacion4Dic from "./pages/analisis/Activacion4Dic_region.jsx";
import Los100 from "./pages/analisis/Los100.jsx";

const Root = () => {
  return (
    <Router>
      <Switch>
        <Route path="/Activacion4Dic" component={Activacion4Dic} />
        <Route path="/analisis" component={Analisis} />
        <Route path="/Los100" component={Los100} />

        {/* <Route path="/login" component={Login} />
        <Route path="/registro" component={Register} />
         */}
         <ProtectedRoute path="/menus" component={Menu} />
        <Route exact path="/" component={Home} />
        <Route exact path="/reconocimientos/:id" component={Reconocimientos} />
        <ProtectedRoute path="/comites" component={Comite} />
        <ProtectedRoute path="/captura_comites" component={ComiteAdd} />
        <ProtectedRoute
          path="/comites_solicitudes"
          component={ComiteSolicitud}
        />
        <ProtectedRoute
          path="/comites_sin_solicitudes"
          component={ComiteSinSolicitud}
        />
        <ProtectedRoute
          path="/captura_comites_solicitudes"
          component={ComiteAddSolicitud}
        />
        {/* Solicitudes */}
        <ProtectedRoute path="/captura_solicitud" component={AddSolicitud} />
        <ProtectedRoute path="/solicitudes" component={ListSolicitud} />
        <ProtectedRoute path="/edit_soliocitud/:id" component={EditSolicitud} />

        <Route path="/acceso_curp" component={CurpAccess} />
        <ProtectedRoute path="/usuarios" component={Users} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
