import React from "react";
import LogoFooter from "../../assets/img/logo-gl.svg"
const Footer = () => {
  return (
    <>
      <footer className="page-footer center-on-small-only mt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center pt-1">
                <a
                  target="_blank"
                  href="https://www.guanajuato.gob.mx/igualdad-laboral.php"
                  rel="opener noreferrer"
                >
                  <img
                    alt="logo"
                    className="img-responsive"
                    src={LogoFooter}
                    width="60"
                    height="60"
                  />
                </a>
                <br />
                <br />
                <b>
                  <a
                    href="http://desarrollosocial.guanajuato.gob.mx/"
                    target="_blank"
                    className="grey-text text-darken-2 text-decoration-none"
                    rel="opener noreferrer"
                  >
                    Secretaría de Desarrollo Social y Humano
                  </a>
                </b>
                <br />
                Paseo de la Presa No. 99 C.P. 36000 Guanajuato, Gto
                <br />
                Teléfono: (473)735 1500, ext. 2284
              </div>
            </div>
          </div>

          <div className="row pt-2">
            <div className="col-12 text-center">
              <a
                rel="noreferrer"
                className="btn-floating"
                href="https://www.facebook.com/pages/SedeshuGto/457581867618996?fref=ts"
                target="_blank"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                rel="noreferrer"
                className="btn-floating"
                href="https://twitter.com/SedeshuGto"
                target="_blank"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                rel="noreferrer"
                className="btn-floating"
                href="https://www.youtube.com/user/SedeshuGto"
                target="_blank"
              >
                <i className="fa fa-youtube-play"></i>
              </a>
            </div>
            <div className="col-12 text-center strong">
              &copy; Gobierno del Estado de Guanajuato, Derechos Reservados
            </div>
          </div>
        </div>
        <div className="container-fluid text-center py-2">
          <p>
            <a
              href="/"
              rel="noopener noreferrer"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Aviso de privacidad
            </a>
          </p>
          <div
            className="modal fade pt-1"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content border-0 z-depth-5">
                <div className="modal-header gto-pink">
                  <h4 className="modal-title text-white" id="exampleModalLabel">
                    Aviso de privacidad
                  </h4>
                  <button
                    type="button"
                    className="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body pt-0 pr-0 pl-0 grey darken-3">
                  <section
                    data="/Tarjeta/Content/documentos/aviso_de_privacidad_SEDESHU.pdf#page=1&amp;zoom=page-width"
                    type="application/pdf"
                    height="800px"
                    width="100%"
                  ></section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
