import React from "react";
import { Card, CardActions, CardContent, Stack, Typography, Box, Divider } from "@mui/material";


const SquareIcon = (props) => {

    return (
        <>
            <Card sx={{ width: "100%", ml: 1, mr: 1 }} key={`${props.title}`}>
                <Stack direction="row">
                    <Box sx={{
                        background: props.background ? props.background : ((props.ColorRigth) ? ('linear-gradient(45deg, ' + props.ColorRigth + ' ' + props.percentageRigth + ',' + props.ColorLeft + ' ' + props.percentageLeft + ')') : "#094DD3"),
                        border: 1,
                        borderRadius: 4,
                        boxShadow: '0 3px 5px 2px rgba(15, 97, 175, .3)',
                        color: 'white',
                        height: 80,
                        width: "10ch",
                        p: 2.5,
                        position: 'relative',
                        bottom: '60%',
                        left: '3%',
                        //zIndex: 'tooltip'
                    }}>
                        <div className="text-center" >
                            {props.IconHeader}
                        </div>
                    </Box>
                    <CardContent sx={{ width: "100%", ml: 1, mr: 1 }} >
                        <div className="text-right">
                            <Typography component="div" variant="overline" sx={{ fontSize: 13, color: "#ACADAF" }} >
                                {props.title}
                            </Typography>
                            <Typography component="div" variant="h4" sx={{ color: 'text.primary', fontSize: 30, fontWeight: 'medium' }}>
                                {props.data}
                            </Typography>
                        </div>
                    </CardContent>
                    <Divider />
                </Stack>
                <Divider sx={{ ml: 2, mr: 2 }} />
                <CardActions sx={{ ml: 1, mr: 1 }}>
                    {props.IconFooder}
                    <Typography component="div" variant="body2" sx={{
                        color: 'primary',
                        display: 'inline',
                        fontWeight: 'medium',
                        mx: 0.5,
                    }} >
                        &nbsp;{props.dataFooder}
                    </Typography>
                </CardActions>
            </Card>
        </>
    );
}
export default SquareIcon;