import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Container,
  Tab,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider
} from "@mui/material";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import Info from "@mui/icons-material/Info";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import BasicList from "../../components/Lists/BasicList";
import UserInformation from "../../components/Users/UserInformation";
import users_list from "./users_data.json";

const Users = () => {
  const [userList, setUserList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 8;
  const config = {
    title: "Listado de Usuarios",
    // icon: faUsers,
    height: 400,
    endMessage: "No hay más registros para mostrar"
  };
  const [tabValue, setTabValue] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const itemComponent = item => {
    return (
      <div key={"itemComponent"+item.id}>
        <ListItem
        
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="info"
              selected={selectedIndex === item.id}
              onClick={event => handleListItemClick(event, item.id)}
            >
              <Visibility />
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton
            selected={selectedIndex === item.id}
            onClick={event => handleListItemClick(event, item.id)}
          >
            <ListItemAvatar>
              <Avatar>
                <AccountCircle />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${item.nombre} ${item.paterno}`}
              secondary={item.user_rol}
            />
          </ListItemButton>
        </ListItem>
        <Divider variant="inset" component="li" />
        </div>
    );
  };

  const getUsers = params => {
    let start = params.page * pageSize;
    let end = (params.page + 1) * pageSize;

    if (end > users_list.length) {
      setHasMore(false);
      return [];
    }

    return users_list.filter(item => {
      return item.id > start && item.id <= end;
    });
  };
  /* const getUsersFromApi = () =>{
    const data = {
      usuario: Username,
      password: Password.values,
      type: "web",
    };

    ApiExec(data, "login", {})
      .then(function (res) {
        return res;
      })
      .then((token) => {
        if (token.results) {
          setVars("Token", token);
          setLoading(false);
          history.push(token.user.DefaultPage);
        } else {
          setLoading(false);
          notify.show(
            `Error de acceso: ${token.message}`,
            "error",
            5000,
            "#FFFFFF"
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error de acceso! ${e.message}`, "custom", 5000, {
          background: "#0E1717",
          text: "#FFFFFF",
        });
      });
  } */
  /* const getUsers = params => {
    let start = params.page * pageSize;
    let end = (params.page + 1) * pageSize;
    if (end > users_list.length) {
      setHasMore(false);
      return [];
    }

    const users = getUsersFromApi();
    return users_list.filter(item => {
      return item.id > start && item.id <= end;
    });
  }; */

  useEffect(() => {
    setTotal(users_list.length);

    setUserList(
      getUsers({
        page: 0
      })
    );
  }, []);

  const fetchHasMore = () => {
    setUserList(
      userList.concat(
        getUsers({
          page: page + 1
        })
      )
    );
    setPage(page + 1);
  };

  const handleChangeTab = (e, newVal) => {
    setTabValue(newVal);
  };

  return (
    <>
      <Header></Header>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm={12} md={12} lg={12} className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                <CardHeader title="Administración de usuarios"></CardHeader>
                <CardContent className="p-0">
                  <Container>
                    <Grid container>
                      <Grid md={12} sm={12} lg={12}></Grid>
                    </Grid>
                    <Grid container>
                      <Grid md={4} sm={12} lg={4}>
                        <BasicList
                          configParams={config}
                          data={userList}
                          fetchHasMore={fetchHasMore}
                          totalItems={total}
                          itemComponent={itemComponent}
                          hasMore={hasMore}
                        />
                      </Grid>
                      <Grid md={8} sm={12} lg={8}>
                        <TabContext.Provider /* value={tabValue} */value={{ tabValue, setTabValue }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                              textColor="secondary"
                              indicatorColor="secondary"
                              centered
                            >
                              <Tab
                                icon={<Info />}
                                label="Información"
                                value={1}
                              />
                              <Tab
                                icon={<AdminPanelSettings />}
                                label="Menús"
                                value={2}
                              />
                              <Tab
                                icon={<ExitToApp />}
                                label="Accesos"
                                value={3}
                              />
                            </TabList>
                          </Box>
                          <TabPanel value={1}>
                            <UserInformation></UserInformation>
                          </TabPanel>
                          <TabPanel value={2}>Item Two</TabPanel>
                          <TabPanel value={3}>Item Three</TabPanel>
                        </TabContext.Provider>
                      </Grid>
                    </Grid>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </>
  );
};
export default Users;
