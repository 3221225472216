import React from 'react';
import {
  Box
  ,Grid
  ,Typography
} from "@mui/material";
import Icon from '@mui/material/Icon';


export default function BoxDashboard() {

  return (
    <Box
      sx={{
        mt: '3ch',
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
        minWidth: 300,
      }}>
      <Box>
        <Grid container >
          <Icon 
            sx={{
              color: 'darkgreen',
              fontSize: 25,
              verticalAlign: 'sub',
              mx: 1.5,
            }}
          >people_alt
          </Icon>
          <Typography 
            sx={{
            fontWeight: 'light',
            fontFamily: 'Helvetica Neue',
            color: 'text.secondary',
            fontSize: 20,
            }}
          >Comite:
          </Typography>
          <Typography
            sx={{
              fontWeight: 'medium',
              fontFamily: 'Helvetica Neue',
              color: 'text.secondary',
              fontSize: 20,
              mx: 1.5,
            }}
          >1</Typography>
        </Grid>
      </Box>
      <Box>
        <Grid container >
          <Icon 
            sx={{
              color: 'lightseagreen',
              fontSize: 25,
              verticalAlign: 'sub',
              mx: 1.5,
            }}
          >description
          </Icon>
          <Typography 
            sx={{
            fontWeight: 'light',
            fontFamily: 'Helvetica Neue',
            color: 'text.secondary',
            fontSize: 20,
            }}
          >Solicitudes:
          </Typography>
          <Typography
            sx={{
              fontWeight: 'medium',
              fontFamily: 'Helvetica Neue',
              color: 'text.secondary',
              fontSize: 20,
              mx: 1.5,
            }}
          >5</Typography>
        </Grid>
      </Box>
    </Box>
  );
}