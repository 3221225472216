import React from 'react';
import {
	Typography
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';

export default function BasicAlerts() {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert 
				severity="info" 
				color="info"
				icon={
					<Icon 
						sx={{
							fontSize: 40,
						}}
					>error_outline
					</Icon>
				} 
			>
			<Typography
					sx={{
						fontWeight: 'light',
						fontFamily: 'Helvetica Neue',
						color: 'GrayText',
						textAlign: 'center',
						fontSize: 25
					}}>¡No cuenta con los permisos necesarios, contacte al administrador!
				</Typography>
			</Alert>
    </Stack>
  );
}