import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FilterStructure } from "../../data/interfaces/Filtro";
import { FilterTypes } from "../../data/constants/Filters";

import {
  Button,
  IconButton,
  Stack,
  Grid,
  Box,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  CircularProgress,
} from "@mui/material";

import {
  Delete,
  DeleteOutlined,
  Add,
  FilterAlt,
  Send,
  ExpandMore,
} from "@mui/icons-material";

import Select from "react-select";
import ReactDatetime from "react-datetime";
import CAlertList from "../Alerts/CAlertList";

import "./Filters.css";

/**
 *
 * *OBLIGATORIO* @param availableFilters se usa para saber que tipo de filtros se mostraran en la pantalla,
 * consta de un array de objetos,
 * cada objeto representa un filtro que se puede aplicar y debe de contar con los siguientes datos:
 * {
 *  value: identificador. Ej. '1',
 *  label: valor a mostrar al usuario. Ej. 'Municipio',
 *  type: tipo de dato de filtro, los valores disponibles se pueden consultar en las constantes 'data/constants/Filtros'. Ej. 'TEXTO',
 *  accesor: nombre del campo al cual hará referencia en la base de datos, se tiene que escibir tal y como está en la tabla a la que
 *  se hará referencia. Ej. 'Municipio',
 *  catalogs: En caso de tener opciones especificas, como es el caso de municipios, este campo sirve para poner esos catalogos,
 *  deben ser pasados como un array para 'react-select', es decir '[{value, label}]'. Ej. '[{value: 1, label: "Opcion 1"}]'
 * }
 * *OBLIGATORIO* @param sendResults Es la funcion a ejecutar, por ejemplo la llamada a la api de listado de municipios,
 * *OPCIONAL* @param isLoading parametro para que el componente sepa cuando estan cargando catálogos,
 * una vez se de click en 'filtrar' o bien en 'limpiar',
 * *OPCIONAL* @param cardClass clase a aplicar para la card del filtro
 * *OPCIONAL* @param cardStyle estilo a aplicar para la card del filtro
 *
 * @returns Al presionar el botón filtrar' o 'limpiar' se ejecuta la función pasada en @param sendResults
 * con los filtros en un array de objetos formateados de la siguiente manera:
 * [
 *  {
 *    field: accesor o campo de la base de datos. Ej. "NumInt"
 *    filter: valor seleccionado como tipo de filtro. Ej. '=',
 *    inheritFilterType: tipo de filtro seleccionado, 'AND' en caso de haber seleccionado 'y',
 *    'OR' en caso de haber seleccionado 'o', null en caso de ser el primer filtro,
 *    value: valor dado en el filtro. Ej. "123"
 *  }
 * ]
 */
const Filter = ({
  availableFilters,
  sendResults,
  cardClass,
  cardStyle,
  isLoading,
}) => {
  const FiltersEndRef = useRef(null);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [filterForm, setFilterForm] = useState(FilterStructure);
  const [filters, setFilters] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleToggleCollapse = () => {
    const isOpen = !collapseIsOpen;
    setCollapseIsOpen(isOpen);
  };

  const handleChangeField = (value) => {
    setFilterTypes(verifyFilters(value.type));
    setFilterForm({
      field: value,
      filterType: { value: 0, label: "Sin seleccionar" },
      value: "",
      type: value.type,
      accesor: value.accesor,
      catalogs: value.catalogs,
    });
  };

  const verifyFilters = (type) => {
    const filterOptions = [
      { value: 1, label: "Igual a" },
      { value: 2, label: "No igual a" },
    ];
    const StringFilterOptions = [
      { value: 3, label: "Contiene" },
      { value: 4, label: "No contiene" },
    ];
    const DateFilterOptions = [
      { value: 5, label: "Antes de" },
      { value: 6, label: "Después de" },
    ];
    const NumberFilterOptions = [
      { value: 7, label: "Mayor a" },
      { value: 8, label: "Menor a" },
    ];
    const MultipleFilterOptions = [
      { value: 9, label: "Se encuentra en" },
      { value: 10, label: "No se encuentra en" },
    ];

    switch (type) {
      case FilterTypes.TEXT:
        return filterOptions.concat(StringFilterOptions);
      case FilterTypes.NUMBER:
        return filterOptions.concat(NumberFilterOptions);
      case FilterTypes.DATE:
        return filterOptions.concat(DateFilterOptions);
      case FilterTypes.MULTIPLE:
        return MultipleFilterOptions;
      default:
        return filterOptions;
    }
  };

  const validateField = (type, opcion) => {
    switch (type) {
      case FilterTypes.TEXT:
        return (
          <TextField
            className="fixed-input"
            variant="standard"
            type="text"
            value={filterForm.value}
            onChange={(e) => handleChangeValue(e.target.value)}
          />
        );
      case FilterTypes.NUMBER:
        return (
          <TextField
            className="fixed-input"
            variant="standard"
            type="number"
            value={filterForm.value}
            onChange={(e) => handleChangeValue(parseInt(e.target.value))}
          />
        );
      case FilterTypes.OPTION:
        return (
          <Select
            options={opcion}
            className="react-select primary"
            classNamePrefix="react-select"
            value={filterForm.value}
            onChange={(e) => handleChangeValue(e)}
            placeholder="Seleccione una opción"
            isLoading={isLoading}
          />
        );
      case FilterTypes.MULTIPLE:
        return (
          <Select
            isMulti
            closeMenuOnSelect={false}
            options={opcion}
            className="react-select primary"
            classNamePrefix="react-select"
            value={filterForm.value}
            onChange={(e) => handleChangeValue(e)}
            placeholder="Seleccione una opción"
            isLoading={isLoading}
          />
        );
      case FilterTypes.DATE:
        return (
          <ReactDatetime
            dateFormat="YYYY/MM/DD"
            timeFormat={false}
            closeOnSelect
            value={filterForm.value}
            onChange={(e) =>
              handleChangeValue(moment(e._d).format("YYYY/MM/DD"))
            }
            inputProps={{ className: "form-control" }}
          />
        );
      default:
        return <h2>No soportado</h2>;
    }
  };

  const handleChangeFilterType = (value) => {
    setFilterForm({ ...filterForm, filterType: value, value: "" });
  };

  const handleChangeValue = (value) => {
    setFilterForm({ ...filterForm, value: value });
  };

  const handleNewFiltro = (e) => {
    e.preventDefault();
    let errors = [];
    if (filterForm.field.value === 0) {
      errors.push("Seleccione un campo a filtrar");
    }
    if (filterForm.filterType.value === 0) {
      errors.push("Seleccione un tipo de fitlro");
    }
    if (filterForm.value === "") {
      errors.push("Ingrese o seleccione el valor del filtro");
    }
    if (errors.length > 0) {
      CAlertList({ elements: errors, title: "¡Error!", icon: "warning" });
    } else {
      const inheritFilterType =
        filters.length === 0 ? null : { value: "AND", label: "y" };
      let newFilter = filterForm;
      newFilter.inheritFilterType = inheritFilterType;
      setFilters([...filters, newFilter]);
      setFilterForm(FilterStructure);
      setFilterTypes([]);
      // FiltersEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setIsButtonClicked(false);
  };

  const handleChangeInherit = (index, value) => {
    let filtersAux = filters.slice();
    filtersAux[index].inheritFilterType = value;
    setFilters(filtersAux);
  };

  const handleDeleteFiltro = (index) => {
    let filtersAux = filters.slice();
    filtersAux.splice(index, 1);
    setFilters(filtersAux);
  };

  const handleFiltersValidation = () => {
    let formatedFilters = [];
    let unusedFilers = [];
    let filtersAux = filters;
    if (
      filterForm.field.value > 0 &&
      filterForm.filterType.value > 0 &&
      filterForm.value !== ""
    ) {
      let newFilter = filterForm;
      newFilter.inheritFilterType =
        filters.length > 0 ? { value: "AND", label: "y" } : null;
      filtersAux.push(newFilter);
    }
    filtersAux.forEach((filter, index) => {
      if (
        filter.field.value > 0 &&
        filter.filterType.value > 0 &&
        filter.value !== ""
      ) {
        const filterType = getFilerType(filter.filterType.value);
        const newFilter = {
          id: filter.accesor,
          filter: filterType,
          value: getFormatedValue(filter.value),
          inheritFilterType: filter.inheritFilterType
            ? filter.inheritFilterType.value
            : null,
        };
        if (
          !formatedFilters.find(
            (element) => JSON.stringify(element) === JSON.stringify(newFilter)
          )
        ) {
          formatedFilters.push(newFilter);
        }
      } else if (filters.length > 1) {
        unusedFilers.push(
          `El ${index + 1} filtro está vacío y no será utilizado`
        );
      }
    });
    if (unusedFilers.length > 0) {
      CAlertList({
        elements: unusedFilers,
        title: "¡Atención!",
        icon: "warning",
      });
    }
    setFilterForm(FilterStructure);
    sendResults(formatedFilters);
    setIsButtonClicked(true);
  };

  const getFilerType = (filterType) => {
    switch (filterType) {
      case 1:
        return "=";
      case 2:
        return "<>";
      case 3:
        return "LIKE";
      case 4:
        return "NOT LIKE";
      case 5:
        return "<";
      case 6:
        return ">";
      case 7:
        return ">";
      case 8:
        return "<";
      case 9:
        return "IN";
      case 10:
        return "NOT IN";
      default:
        return "=";
    }
  };

  const getFormatedValue = (value) => {
    switch (typeof value) {
      case "object":
        if (Array.isArray(value)) {
          let selectedValues = [];
          value.forEach((element) => {
            selectedValues.push(element.value);
          });
          return selectedValues;
        }
        return value.value;
      case "number":
        return parseInt(value);
      default:
        return value;
    }
  };

  const validateListValue = (value) => {
    switch (typeof value) {
      case "object":
        if (Array.isArray(value)) {
          let commasDividedString = "";
          value.forEach((element) => {
            commasDividedString += `${element.label}, `;
          });
          return commasDividedString;
        }
        return value.label;
      default:
        return value;
    }
  };

  const handleCleanFilter = () => {
    setFilterForm(FilterStructure);
    setFilters([]);
    sendResults([]);
    setIsButtonClicked(false);
  };

  return (
    <>
      <Accordion
        style={cardStyle}
        className={cardClass}
        onChange={handleToggleCollapse}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMore
              className={collapseIsOpen ? "filter-icon-active" : "filter-icon"}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={collapseIsOpen ? "card-filter-active" : "card-filter"}
        >
          <Grid container>
            <Grid item xs={11}>
              {" "}
              <Typography>
                <FilterAlt /> Filtros{" "}
                {!collapseIsOpen &&
                  filters.length > 0 &&
                  isButtonClicked &&
                  `(${filters.length})`}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {isLoading && (
                <CircularProgress size={25} sx={{ color: "white" }} />
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box px={3} sx={{ textAlign: "center" }}>
            <Typography
              variant="h7"
              color="#0D47A1"
              sx={{ textAlign: "center" }}
            >
              Nuevo filtro
            </Typography>
          </Box>
          <Box component="form" onSubmit={(e) => handleNewFiltro(e)}>
            <Grid
              container
              spacing={3}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item xs sx={{ paddingTop: "0px" }}>
                <Select
                  sx={{ paddingTop: "0px" }}
                  options={availableFilters}
                  className="react-select primary"
                  classNamePrefix="react-select"
                  value={filterForm.field}
                  onChange={(e) => handleChangeField(e)}
                  placeholder="Seleccione una opción"
                  size="small"
                />
              </Grid>
              <Grid item xs>
                {filterForm.field.value > 0 && (
                  <Select
                    options={filterTypes}
                    value={filterForm.filterType}
                    onChange={(e) => handleChangeFilterType(e)}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    placeholder="Seleccione una opción"
                  />
                )}
              </Grid>
              <Grid item xs>
                {filterForm.field.value > 0 &&
                  validateField(filterForm.type, filterForm.catalogs)}
              </Grid>
              <Grid item lg={1} md={1} sm={1}>
                {filterForm.field.value > 0 && (
                  <IconButton size="sm" color="secondary" type="submit">
                    <Add size="lg" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Box>
          <hr />
          {filters.length > 0 && (
            <Box px={3} className="title">
              <h4>
                {isButtonClicked ? "Filtros aplicados" : "Filtros por aplicar"}:{" "}
                {filters.length}
              </h4>
            </Box>
          )}
          <div className="scroll-div">
            {filters.map((filter, index) => {
              return (
                <Box key={index} className="row-filter" px={20}>
                  <div className="card-box">
                    <Grid
                      container
                      justifyContent="center"
                      className="filter-card"
                    >
                      <Grid item xs={2}>
                        {index > 0 && (
                          <Select
                            options={[
                              { value: "AND", label: "y" },
                              { value: "OR", label: "o" },
                            ]}
                            className="react-select primary filter-card"
                            classNamePrefix="react-select"
                            value={filter.inheritFilterType}
                            onChange={(e) => handleChangeInherit(index, e)}
                            placeholder="Seleccione una opción"
                          />
                        )}
                      </Grid>
                      <Grid item xs sx={{ alignSelf: "center" }}>
                        <Typography>
                          {filter.field.label} <b>{filter.filterType.label}</b>{" "}
                          {validateListValue(filter.value)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {" "}
                        <IconButton
                          size="sm"
                          onClick={() => handleDeleteFiltro(index)}
                          color="error"
                        >
                          <DeleteOutlined size="lg" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              );
            })}
            <div className="reference-div" ref={FiltersEndRef}></div>
          </div>
        </AccordionDetails>
        <AccordionActions>
          <Stack direction="row" spacing={2} className="actions-buttons-col">
            <Button
              variant="outlined"
              size="small"
              color="success"
              onClick={handleFiltersValidation}
              startIcon={<Send />}
            >
              Filtrar
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleCleanFilter}
              startIcon={<Delete />}
            >
              Limpiar
            </Button>{" "}
          </Stack>
        </AccordionActions>
      </Accordion>
    </>
  );
};

Filter.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  sendResults: PropTypes.func.isRequired,
  cardClass: PropTypes.string,
  cardStyle: PropTypes.object,
  isLoading: PropTypes.bool,
};

Filter.defaultProps = {
  cardClass: "",
  cardStyle: {},
  isLoading: false,
};

export default Filter;
