import React, { useState, useEffect} from "react";
import {
  Grid
  , Card
  , CardContent
  , Container
  , Button
  , Box
  , IconButton
} from "@mui/material";
import swal from "sweetalert";
import Icon from '@mui/material/Icon';
import ApiExec from "../../utils/ApiExec";
import { getDataMenu } from './../../data/menus';
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import { getVars } from "./../../utils/GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import EditComite from '../../components/Comite/ComiteEdit';
import Tablacomite from "./../../components/Comite/TablaComite";
import { ComiteEditSchema } from "../../data/schemas/ComiteSchemas"
import { ComiteEditInterface } from "../../data/interfaces/ComiteInterfaces";
import FilterComite from "../../components/Filters/Filter"
import {FilterTypes} from "../../data/constants/Filters"
import moment from "moment";

const Comite = () => {
  const token = getVars("Token");
  const [_menu, _setmenu] = useState({});
  const [ListComite, setListComite] = useState([]);
  const [Comite, setComite] = useState(ComiteEditInterface);
  const [Filter, setFilter] = useState([]);
  const [openmodalEditComite, setOpenModalEditComite] = useState(false);

  const columns = [
    { id: "id", label: "Nº" },
    { id: "Fecha", label: "FechaComite" },
    { id: "Acta", label: "Nº de acta" },
    { id: "idPrograma", label: "Programa" },
    { id: "Beneficiarios", label: "Total Solicitudes" },
    { id: "Estatus", label: "Estatus" },
    { id: "Accion", label: "Accion"}
  ];

  const getRegistrosComite = (data) => {
    ApiExec(data, "getComites", token.access_token, {})
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let aux = res.data.map(item => {
            return {
              id: item.id,
              Fecha: item.Fecha,
              Acta: item.Acta,
              idPrograma: item.Programa,
              Beneficiarios: <Button variant="text" color="success" href="#/comites_solicitudes">{item.Beneficiarios}</Button>,
              /* item.Beneficiarios > 0 ? 
              (<Button variant="text" color="success" href="#/comites_solicitudes">{item.Beneficiarios}</Button>):
              (<Button variant="text" color="error" href="#/comites_sin_solicitudes">{item.Beneficiarios}</Button>), */
              Estatus: item.Estatus,
              Accion: item.idStatus === 1 ?
                <IconButton variant="text" size="small" onClick={() => { DatosEditar(item); }}>
                  <Icon sx={{ color: '#638C1C' }}>edit</Icon>
                </IconButton> : <IconButton size="small" sx={{ color: 'InactiveBorder' }}>edit_off</IconButton>
            }
          })
          setListComite(aux)
        }
      })
      .catch((e) => {
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857b6',
          fontSize: 14,
          text: '#FFFF'
        });
      })
  };

  useEffect(() => {
    _setmenu(getDataMenu(10, token.menus))
    getRegistrosComite({})
  }, []);

  const DatosEditar = (idComite) => {
    setOpenModalEditComite(true)
    setComite({
      id: idComite.id,
      idTipo: idComite.idTipo,
      Leyenda: idComite.Leyenda,
      idPrograma: idComite.idPrograma,
      Fecha: idComite.Fecha
    });
  }

  const handleChangePagination = (e) => {
  };

  const UpdateComite = (Data) => {
    var dato = {
      id: Data.id,
      idPrograma: Data.idPrograma,
      Fecha: Data.Fecha.substr(0, 10),
      Leyenda: Data.Leyenda,
      idTipo: Data.idTipo
    }
    ApiExec(dato, "/updateComite", token.access_token)
      .then(function (res) {
        return res
      })
      .then((res) => {
        if (res.results) {
          setOpenModalEditComite(false)
          getRegistrosComite();
          swal({
            title: res.message,
            icon: "success",
            dangerMode: true,
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                className: "btn-success",
                closeModal: true
              }
            }
          })
        } else {
          swal({
            title: "Generado Exitosamente",
            icon: "error",
            buttons: {
              confirm: {
                text: "Ok",
                value: true,
                visible: true,
                className: "btn-warning",
                closeModal: true
              }
            }
          })
        }
      })
      .catch((e) => {
        notify.show(`Error: ${e.error}`, 'custom', 5000, {
          background: '#0857b6',
          fontSize: 14,
          text: '#FFFF'
        });
      })
  }

  const availableFilters = [
    {
      value: 1,
      label: "Fecha",
      type: FilterTypes.DATE,
      accesor: "comite.Fecha"
    }
  ]

  const sendResults = (formatedFilters) =>{
    setFilter(formatedFilters)
    getRegistrosComite({
      filtered: formatedFilters
    })
    
  }

  return (
    <>
      <Header></Header>
      <Notifications></Notifications>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm="12" md="12" lg="12" className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                <CardContent className="p-0">
                  <Container>
                    <Box >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: { xs: 'center', md: 'flex-end' },
                          m: 1,
                          minWidth: { md: 350 },
                        }}
                      >
                      </Box>
                      <Grid container >
                        <Grid item xs={12} md={12} lg={12}>
                          <FilterComite
                            availableFilters={availableFilters}
                            sendResults={sendResults}
                          />
                          <Tablacomite
                            isPermisoAgregar={_menu.Agregar === 1}
                            rows={ListComite}
                            hcolumns={columns}
                            rowCount={ListComite}
                            pageSize={5}
                            pageProp={1}
                            Dato="comite"
                            Titulo="Listado Comites" 
                            link="#/captura_comites" 
                            captura_comites_solicitudes
                            handleChangePagination={(e) =>
                              handleChangePagination(e)}
                          />
                        </Grid>
                        {openmodalEditComite && (
                          <EditComite
                            isPermisoEditar={_menu.Editar === 1}
                            send={UpdateComite}
                            initialValues={Comite}
                            isOpen={openmodalEditComite}
                            validationSchema={ComiteEditSchema}
                            setIsOpen={() =>
                              setOpenModalEditComite(!openmodalEditComite)
                            }
                          />
                        )}
                      </Grid>
                    </Box>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </>
  );
};
export default Comite;
