import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getVars } from './GlobalStorage';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			render={(props) => (!getVars('Token').access_token ? <Redirect to="/login" /> : <Component {...props} />)}
			{...rest}
		/>
	);
};

export default ProtectedRoute;
