import React, { useState } from "react";
import Logo from "../../assets/SIAN_Transparente.png";
import { NavLink } from "react-router-dom";
import { getVars, setVars } from "../../utils/GlobalStorage";
const Navbar = (props) => {
  const [state] = useState({
    openAvatar: false,
    componentsCollapse: true,
  });

  const setMenu = (menu) => {
    setVars("CurrentMenu", { CurrentMenu: menu });
  };

  /* const logout = () => {
		setVars('Token', { access_token: null });
	}; */
  const createSubLinks = (routes) => {
    if (routes.length < 1) return;
    return routes.map((itemMenu, key) => {
      if (itemMenu.redirect) {
        return null;
      }

      if (itemMenu.Ver === 0) {
        return null;
      }

      return (
        <div key={key + "SubMenusInternos"}>
          <NavLink
            className="dropdown-item"
            to={{ pathname: itemMenu.path }}
            rel={
              itemMenu.path !== undefined && itemMenu.path !== null
                ? "opener noreferrer"
                : "noopener noreferrer"
            }
          >
            {itemMenu.icon !== "undefined" ? (
              <>
                <i className={itemMenu.icon} />
                {" " + itemMenu.name.toUpperCase()}
              </>
            ) : (
              <>{itemMenu.name.toUpperCase()}</>
            )}
          </NavLink>
          {/* AQUI EMPIEZA PARA SUB-SUB-MENU */}

          {/* AQUI TERMINA SUB-SUB-MENU */}
        </div>
      );
    });
  };

  const createLinks = (routes) => {
    if (routes.length < 1) return;
    return routes.map((itemMenu, key) => {
      if (itemMenu.redirect) {
        return null;
      }

      if (itemMenu.Ver === 0) {
        return null;
      }
      if (itemMenu.collapse === 1) {
        var st = {};
        st[itemMenu["state"]] = !state[itemMenu.state];
        return (
          <li className="nav-item btn-group" key={key + "SubMenus"}>
            <NavLink
              className="nav-link dropdown-toggle"
              /* id="dropdownMenu1" */
              data-toggle="dropdown"
              aria-expanded="false"
              to={{ pathname: itemMenu.path }}
              rel={
                itemMenu.path !== undefined && itemMenu.path !== null
                  ? "opener noreferrer"
                  : "noopener noreferrer"
              }
            >
              {itemMenu.icon !== "undefined" ? (
                <>
                  <i className={itemMenu.icon} />
                  {" " + itemMenu.name.toUpperCase()}
                </>
              ) : (
                <>{itemMenu.name.toUpperCase()}</>
              )}
            </NavLink>
            <>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu1">
                {createSubLinks(itemMenu.views)}
              </div>
            </>
          </li>
        );
      }

      return (
        <li
          className="nav-item btn-group"
          key={key + "MenusPrincipales"}
          onClick={() => setMenu(itemMenu.name)}
        >
          <NavLink
            className="nav-link"
            to={{ pathname: itemMenu.path }}
            rel="noopener noreferrer"
          >
            {itemMenu.icon !== "undefined" ? (
              <>
                <i className={itemMenu.icon} />
                {" " + itemMenu.name.toUpperCase()}
              </>
            ) : (
              <>{itemMenu.name.toUpperCase()}</>
            )}
          </NavLink>
        </li>
      );
    });
  };

  return (
    <>
      <div id="nav">
        <nav
          id="nav-top-second"
          className="navbar fixed-top navbar-toggleable-md navbar-dark scrolling-navbar mt-2 p-0"
          style={{ backgroundColor: "#000f9f" }}
        >
          <div className="container">
            <button
              className="pull-right m-0 btn btn-lg navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ height: "100%" }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand p-1 m-0" href="/">
              <img
                src={Logo}
                className="d-inline-block align-top wow fadeInLeft img-fluid"
                data-wow-delay="0.6s"
                alt="SIAN"
                style={{ maxWidth: "50px", borderRadius: "20px!important" }}
              />
            </a>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ml-auto">
                <li
                  className="nav-item btn-group"
                  key={"HomeMenuPrincipal"}
                  onClick={() => setMenu("Home")}
                >
                  <NavLink
                    className="nav-link"
                    to={{ pathname: "/" }}
                    rel="opener noreferrer"
                  >
                    <>
                      <i className={"fa fa-home"} />
                      {" Inicio"}
                    </>
                  </NavLink>
                </li>
                {createLinks(getVars("Token", { menus: [] }).menus)}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
export default Navbar;
