import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Paper,
  InputBase,
  IconButton,
  Avatar,
  List
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { blue } from "@mui/material/colors";
import InfiniteScroll from "react-infinite-scroll-component";
import { RotateSpinner } from "react-spinners-kit";

const BasicList = ({
  configParams,
  data,
  fetchHasMore,
  totalItems,
  itemComponent,
  hasMore
}) => {
  const [showedItems, setShowedItems] = useState(0);
  const [listingData, setListingData] = useState([]);

  const [config, setConfig] = useState({
    title: "Listado",
    // icon: faList,
    height: 400,
    endMessage: "No hay registros para mostrar"
  });

  useEffect(() => {
    Object.keys(configParams).forEach(function(key) {
      setConfig(prevState => ({ ...prevState, [key]: configParams[key] }));
    });
  }, [configParams]);

  useEffect(() => {
    setListingData(data);
    setShowedItems(data.length);
  }, [data]);

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe"></Avatar>
        }
        title={config.title}
      ></CardHeader>
      <CardContent>
        <Container>
          <Grid sm={12}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center"
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Digite al menos 3 caracteres"
                inputProps={{ "aria-label": "Digite al menos 3 caracteres" }}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <Search />
              </IconButton>
            </Paper>
            <InfiniteScroll
              dataLength={showedItems}
              next={fetchHasMore}
              hasMore={hasMore}
              loader={<RotateSpinner color={"#1261ff"} size={30} />}
              height={config.height}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>
                    {config.endMessage !== undefined
                      ? config.endMessage
                      : "¡Final de la lista de registros!"}
                  </b>
                </p>
              }
            >
              <List dense={true}>
                {listingData.map((i, index) => itemComponent(i))}
              </List>
            </InfiniteScroll>
          </Grid>
        </Container>
      </CardContent>
      <CardActions>
        <small>
          Mostrando {showedItems} de {totalItems}
        </small>
      </CardActions>
    </Card>
  );
};

export default BasicList;
