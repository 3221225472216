import React from 'react';
import {
		Box 
	, Modal
	, Radio
	,	Stack
	, Button
	, Divider
	, TextField
	, RadioGroup
	, Typography
	, FormControl
	, FormControlLabel
} from '@mui/material';
import { useFormik } from "formik";
import Select from "../Select/Select";
import Icon from '@mui/material/Icon';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { getDate } from "../../utils/AuxiliarFunction";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { isEmptyOrInvalidString } from "../../utils/validations";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const style = {
	p: 4,
	top: '50%',
	left: '50%',
	width: 380,
	height: 500,
	boxShadow: 24,
	position: 'absolute',
	bgcolor: 'background.paper',
	transform: 'translate(-50%, -50%)',
};

const BasicModal = props => {
	const { 
		send, 
		isOpen, 
		setIsOpen, 
		initialValues, 
		validationSchema,
	} = props;

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			send(values);
		}
	});

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={setIsOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						sx={{
							fontWeight: 'light',
							fontFamily: 'Helvetica Neue',
							color: 'GrayText',
							textAlign: 'center',
							mb: 2
						}}> Editar Comite
					</Typography>
					<Divider
						sx={{ mb: 1 }}
					/>
					<Box
						component="form"
						onSubmit={formik.handleSubmit}
						sx={{
							"& > :not(style)": { m: 1, width: "30ch" },
							flexGrow: 1,
						}}
					>
						<Select
							error={
								formik.touched.idPrograma &&
								!isEmptyOrInvalidString(formik.errors.idPrograma)
							}
							required={true}
							fullWidth={true}
							title="Programa Social"
							api="getProgramasSociales"
							name="idPrograma"
							_selected={formik.values.idPrograma}
							onChange={formik.handleChange}
							helperText={
								formik.touched.idPrograma &&
								formik.errors.idPrograma &&
								formik.errors.idPrograma
							}
							onBlur={formik.handleBlur}
						/>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DesktopDatePicker
								error={
									formik.touched.Fecha &&
									!isEmptyOrInvalidString(formik.errors.Fecha)
								}
								required={true}
								label="Fecha"
								name="Fecha"
								inputFormat="dd/MM/yyyy"
								format="dd/MM/yyyy"
								value={formik.values.Fecha}
								onChange={(e) => {
									let fecha = getDate({ fecha: e })
									formik.handleChange({ target: { name: "Fecha", value: fecha } });
								}}
								helperText={
									formik.touched.Fecha &&
									formik.errors.Fecha &&
									formik.errors.Fecha
								}
								onBlur={formik.handleBlur}
								renderInput={(params) => (
									<TextField variant="standard" {...params} />
								)}
							/>
						</LocalizationProvider>
						<Stack direction="row" sx={{
							"& > :not(style)": {width: "150ch" },
						}}>
							<TextField
								label="Leyenda"
								name="Leyenda"
								multiline
								rows={3}
								id="Leyenda"
								type="email"
								value={formik.values.Leyenda}
								onChange={formik.handleChange}
								error={
									formik.touched.Leyenda &&
									!isEmptyOrInvalidString(formik.errors.Leyenda)
								}
								helperText={
									formik.touched.Leyenda &&
									formik.errors.Leyenda &&
									formik.errors.Leyenda
								}
								onBlur={formik.handleBlur}
							/>
						</Stack>
						<FormControl sx={{ minWidth: 500}} >
							<RadioGroup
								row aria-label="idTipo"
								name="idTipo"
								defaultValue="top"
								value={formik.values.idTipo}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							>
								<FormControlLabel
									value={1}
									control={<Radio color="secondary" size="small" />}
									label="Extraordinario"
									labelPlacement="start"
								/>
								<FormControlLabel
									value={2}
									control={<Radio color="secondary" size="small" />}
									label="Ordinario"
									labelPlacement="start"
								/>
							</RadioGroup>
						</FormControl>
						<Box
							style={{ textAlign: 'right', margin: "auto" }}
							sx={{
								"& > :not(style)": { m: 1, width: "70ch", position: "relative" },
							}}
						>
							<Button
								sx={{ maxWidth: 130 }}
								type="submit"
								size="sm"
								variant="outlined"
								startIcon={<Icon>update</Icon>}
							>
								Actualizar
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
export default BasicModal;
