import { getDate } from "../../utils/AuxiliarFunction"; 

const ComiteEditInterface = {
	idTipo: '',
	Leyenda: '',
	idPrograma: '',
	Fecha: "",
};

const ComiteAddInterface = {
	idTipo: '',
	Leyenda: '',
	idPrograma: '',
	Fecha: getDate({}),
};

export {
	ComiteEditInterface,
	ComiteAddInterface
}