import React, { useState } from "react";
import {
	TableContainer,
	Table,
	TablePagination,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Box,
	Tooltip,
	Paper
	, Button
} from "@mui/material";
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { borderColor } from "@mui/system";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { 
		order, 
		orderBy, 
		onRequestSort, 
		headCells 
	} =props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<>
						<TableCell
							key={headCell.id}
							padding='normal'
							sortDirection={orderBy === headCell.id ? order : false}
							width={headCell.width}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								<Typography
									sx={{
										textAlign: 'start',
										fontWeight: 'light',
										fontSize: 16,
										fontFamily: 'Helvetica Neue'
									}}>{headCell.label}
								</Typography>
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell></>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const {
		isPermisoAgregar,
		Titulo,
		link
	}= props
	return (
		<Toolbar
			sx={{
				borderBottom: 1, borderColor: "divider",
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
			}}
		>
			<Typography
				variant="subtitle1"
				component="header"
				sx={{
					flex: '1 1 100%',
					fontWeight: 'light',
					fontSize: 20,
					fontFamily: 'Helvetica Neue',
				}}
			>{Titulo}
			</Typography>
			{/* {isPermisoAgregar && ( */}
			<Tooltip title="Agregar Comite">
				<Button
					variant="outlined"
					size="small"
					color="success"
					href={link}
					startIcon={<Icon >add</Icon>}
				>
					Capturar
				</Button>
			</Tooltip>
		 {/* )}  */}
		</Toolbar>
	);
};

export default function EnhancedTable(props) {
	const {
		rows, 
		hcolumns,
		Titulo, 
		isPermisoAgregar,
		link 
	}=props
	const [headCells] = useState(hcolumns);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('calories');
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	return (
		<Box sx={{ width: '100%', overflow: "auto" }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar  
					Titulo= {Titulo} 
					isPermisoAgregar={isPermisoAgregar}
					link={link}
				/>
				<TableContainer>
					<Table aria-label="sticky table"
						sx={{ minWidth: 750 }}
						size={'small'}

					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							headCells={headCells}
						/>
						<TableBody>
							{stableSort(rows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow hover>
											{hcolumns.map(colum => {
												return row[colum.id] ?
													(<>
														<TableCell >
															<Typography
																sx={{
																	fontWeight: 'light',
																	fontSize: 14,
																	fontFamily: 'Helvetica Neue',
																	color: 'currentcolor',
																}}>
																{row[colum.id]}
															</Typography>
														</TableCell>
													</>) :
													<>
														<TableCell>{" "}</TableCell></>
											})}
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow>
									<TableCell colSpan={6} >Sin Registros</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

		</Box>
	);
}
