import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, Container, Box } from "@mui/material";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";

import _ from "lodash";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import BarrasDobles from "../../components/Charts/AnalsisRevocacion/BarrasDobles";
import BarraMeta from "../../components/Charts/AnalsisRevocacion/BarraMeta";
import ComparativoRevocacion from "../../data/ComparativoRevocacion.json";
import Meta2024 from "../../data/municipios_lnom_meta24.json";
import Censo2020 from "../../data/municipios_censo2020.json";
import Militantes from "../../data/municipio_militantes.json";
import BloqueParidad from "../../data/bloques_paridad_cdm.json";
import MilitantesStats from "../../data/MilitantesSexo_30Sept2022.json";
import MetaSeccion24 from "../../data/MetaSeccion24.json";

import {
  numberWithCommas,
  icoPartido,
  removeDiacritics,
} from "./../../utils/Functions";

import Notifications from "react-notify-toast";

import TimeLine from "../../components/Charts/TimeLine/index";
import resTendencia from "../../data/results_tendencia_2012_2021.json";
import resTendHistorico from "../../data/results_historicos_2012_2021.json";
import resHistorico from "../../data/results_historico_2012_2021.json";
import resParticipacion from "../../data/results_participacion_2015_2021.json";
import resSecciones from "../../data/results_seccioneslnom_2021.json";

import icoPAN from "../../assets/partidos/PARTIDOS_PAN.png";
import icoPRI from "../../assets/partidos/PARTIDOS_PRI.png";
import icoPRD from "../../assets/partidos/PARTIDOS_PRD.png";
import icoPT from "../../assets/partidos/PARTIDOS_PT.png";
import icoPVEM from "../../assets/partidos/PARTIDOS_PVEM.png";
import icoMC from "../../assets/partidos/PARTIDOS_MC.png";
import icoMORENA from "../../assets/partidos/PARTIDOS_MORENA.png";
import icoNA_Gto from "../../assets/partidos/PARTIDOS_NA.png";

import "./styles.css";

const fr1 = 16;
const fr2 = 56;
const fr3 = 96;
const fr4 = 140;
const fr5 = 180;
const fr6 = 222;
const fr7 = 262;
const fr8 = 302;
const fr9 = 342;
const fr10 = 382;
const fr11 = 422;
const fr12 = 462;
const fr13 = 502;
const fr14 = 542;
const fr15 = 582;
const fr16 = 622;
const fr17 = 662;
const fr18 = 702;
const fr19 = 742;
const fr20 = 782;
const fr21 = 822;
const fr22 = 862;
const fr23 = 902;
const fr24 = 942;
const fr25 = 982;
const fr26 = 1022;
const fr27 = 1062;
const fr28 = 1102;

const Analisis = () => {
  const [isLoading, setLoading] = useState(false);
  const [isPrintLoading, setPrintLoading] = useState(false);
  const [filtro, setFilter] = useState({ value: 1, label: "Abasolo" });
  const [availableFilters, setAvailableFilters] = useState([
    { value: null, label: "Seleccione" },
  ]);

  const [ultimoHistorico, setUltimoHistorico] = useState([]);
  const [ultimoHistoricoDetalle, setUltimoHistoricoDetalle] = useState([]);
  const [infoSecciones, setInfoSecciones] = useState(null);
  const [dataRevocacion, setDataRevocacion] = useState(null);
  const [infoHist, setInfoHist] = useState(null);
  const [infBloqueCDM, setBloqueCDM] = useState(null);
  const [meta24, setMeta24] = useState(null);
  const [metaSeccion24, setMetaSeccion24] = useState([]);
  const [censo20, setCenso20] = useState(null);
  const [militanteData, setMilitantesData] = useState(null);
  const [militantesStats, setMilitantesStats] = useState(null);
  const [chartPart, setChartPart] = useState(null);
  const [chartTendencia, setChartTendencia] = useState(null);
  const [page1, setPage1] = useState(null);
  const [page2, setPage2] = useState(null);
  const [page3, setPage3] = useState(null);
  const [page4, setPage4] = useState(null);
  const [page5, setPage5] = useState(null);
  const [page6, setPage6] = useState(null);
  const [page7, setPage7] = useState(null);
  const [page8, setPage8] = useState(null);
  const [page9, setPage9] = useState(null);
  const [page10, setPage10] = useState(null);
  const [page11, setPage11] = useState(null);
  const [page12, setPage12] = useState(null);
  const [page13, setPage13] = useState(null);
  const [page14, setPage14] = useState(null);
  const [page15, setPage15] = useState(null);
  const [page16, setPage16] = useState(null);
  const [page17, setPage17] = useState(null);
  const [page18, setPage18] = useState(null);
  const [page19, setPage19] = useState(null);
  const [page20, setPage20] = useState(null);
  const [page21, setPage21] = useState(null);
  const [page22, setPage22] = useState(null);

  useEffect(() => {
    //_setmenu(getDataMenu(10, token.menus));
    setLoading(true);

    console.log("=>Final: ", resTendencia.data);
    console.log("=>label: ", filtro.label);

    console.log("=>MetaSeccion24: ", MetaSeccion24);

    const meSeccion24 = MetaSeccion24.data.filter(
      (item) => item.cve_mun === filtro.value
    );

    const ordenSecciones = _.orderBy(meSeccion24, "Participacion", "desc");

    setMetaSeccion24(ordenSecciones);

    console.log("=>Secc Part: ", ordenSecciones);
    console.log("=>SeccmetaSeccion24: ", metaSeccion24);

    const m24 = Meta2024.data.filter((item) => item.cve_mun === filtro.value);

    setMeta24(m24[0]);

    const cen20 = Censo2020.data.filter(
      (item) => item.cve_mun === filtro.value
    );

    setCenso20(cen20[0]);

    const bloque_cdm = BloqueParidad.data.filter(
      (item) => item.cve_mun === filtro.value
    );

    setBloqueCDM(bloque_cdm[0]);

    const militantes_data = MilitantesStats.data.filter(
      (item) => item.idMunicipio === filtro.value
    );

    setMilitantesStats(militantes_data[0]);

    const miliData = Militantes.data.filter(
      (item) => item.cve_mun === filtro.value
    );
    setMilitantesData(miliData[0]);

    const resTendHistoricoTmp = _.orderBy(
      resTendHistorico.data,
      ["idTIPO", "Eleccion"],
      ["asc", "desc"]
    );

    // console.log("=>resTendHistorico: ", resTendHistorico.data);

    // console.log("=>resTendHistoricoTmp: ", resTendHistoricoTmp);

    const UlHistorico = resTendHistoricoTmp.filter(
      (item) => item.Eleccion === 2021 && item.cve_mun === filtro.value
    );

    // console.log("=>resTendHistoricoFiltro: ", UlHistorico);

    const UlHistoricoDet = resTendHistoricoTmp.filter(
      (item) => item.Eleccion !== 2021 && item.cve_mun === filtro.value
    );

    // console.log("=>resTendHistoricoSort: ", UlHistoricoDet);

    const ordHisto = _.sortBy(UlHistoricoDet, ["Tipo"], ["desc"]);

    // console.log("=>Sort Order: ", ordHisto);

    setUltimoHistorico(UlHistorico);
    setUltimoHistoricoDetalle(UlHistoricoDet);

    var items = [];
    const dataRev = ComparativoRevocacion.data.filter(
      (item) => item.cve_mun === filtro.value
    );

    dataRev.map((item) => {
      setDataRevocacion({
        title: `Análisis votación vs Revocación ${item.Municipio}`,
        categories: ["2015", "2018", "2021", "2022"],
        pan: [
          parseInt(item.PAN15),
          parseInt(item.PAN18),
          parseInt(item.PAN21),
          parseInt(item.EnContra),
        ],
        morena: [
          parseInt(item.Morena15),
          parseInt(item.Morena18),
          parseInt(item.Morena21),
          parseInt(item.Morena22),
        ],
        morena21: [parseInt(item.Morena21)],
        morena22: [parseInt(item.Morena22)],
        crecimiento: parseFloat(item.PorcCrecio).toFixed(2),
        diffVotos: parseFloat(item.Diffvs21),
        subtitle:
          parseFloat(item.PorcCrecio) > 0
            ? "Crecio " + parseFloat(item.PorcCrecio).toFixed(2) + " %"
            : "Perdio " + parseFloat(item.PorcCrecio).toFixed(2) + " %",
        title2:
          parseFloat(item.Diffvs21) > 0
            ? "Gana " +
              numberWithCommas(parseFloat(item.Diffvs21).toFixed(0)) +
              " votos"
            : "Pierde " +
              numberWithCommas(parseFloat(item.Diffvs21).toFixed(0)) +
              " votos",
      });
    });

    // console.log("=>Rev: ", items);

    const iSecc = resSecciones.data.filter(
      (item) => item.cve_mun === filtro.value
    );

    // console.log("=>iSecc: ", iSecc);

    setInfoSecciones(iSecc[0]);

    const options = resHistorico.data.map((item) => {
      return { value: item.cve_mun, label: item.MUNICIPIO };
    });
    setAvailableFilters(options);

    const histo = resHistorico.data.filter(
      (item) => item.cve_mun === filtro.value
    );

    console.log("=>histo: ", histo);
    setInfoHist(histo[0]);

    const Categorias = [];
    const PartData = [];
    const AbstData = [];

    const tCategorias = [];
    const tPAn = [];
    const tPRI = [];
    const tPRD = [];
    const tPT = [];
    const tPVEM = [];
    const tMC = [];
    const tMORENA = [];

    const tmpTend = resTendHistorico.data.filter(
      (item) =>
        item.cve_mun === filtro.value &&
        item.Tipo.toUpperCase() === "AYUNTAMIENTO"
    );

    console.log("=>tmpTend: ", tmpTend);

    const OrdenadoTend = _.sortBy(tmpTend, ["Eleccion"]);

    console.log("=>OrdenadoTend: ", OrdenadoTend);

    OrdenadoTend.forEach((item, index) => {
      tCategorias.push(`${item.Eleccion}`);

      tPAn.push(parseFloat(item.PAN).toFixed(2));
      tPRI.push(parseFloat(item.PRI).toFixed(2));
      tPRD.push(parseFloat(item.PRD).toFixed(2));
      tPT.push(parseFloat(item.PT).toFixed(2));
      tPVEM.push(parseFloat(item.PVEM).toFixed(2));
      tMC.push(parseFloat(item.MC).toFixed(2));
      tMORENA.push(parseFloat(item.MORENA).toFixed(2));
    });

    const objTendencia = {
      Categorias: tCategorias,
      yAxis: {
        title: {
          text: "Tendencia",
        },
      },
      xAxis: {
        categories: tCategorias,
        accessibility: {
          rangeDescription: "Range: 2012 to 2021",
        },
      },
      series: [
        {
          name: "PAN",
          data: tPAn.map(Number),
        },
        {
          name: "PRI",
          data: tPRI.map(Number),
        },
        {
          name: "PRD",
          data: tPRD.map(Number),
        },
        {
          name: "PT",
          data: tPT.map(Number),
        },
        {
          name: "PVEM",
          data: tPVEM.map(Number),
        },
        {
          name: "MC",
          data: tMC.map(Number),
        },
        {
          name: "MORENA",
          data: tMORENA.map(Number),
        },
      ],
    };

    setChartTendencia(objTendencia);

    // console.log("=>objTendencia: ", objTendencia);

    const OrdenadoPart = _.sortBy(resParticipacion.data, ["Eleccion"]);

    // console.log("=>OrdenadoPart.data: ", OrdenadoPart);

    OrdenadoPart.forEach((item, index) => {
      if (
        removeDiacritics(item.MUNICIPIO.toUpperCase()) ===
        removeDiacritics(filtro.label.toUpperCase())
      ) {
        Categorias.push(`${item.Eleccion}`);
        PartData.push(parseFloat(item.PP).toFixed(2));
        AbstData.push(parseFloat(item.Abst).toFixed(2));

        tPAn.push(parseFloat(item.PAN).toFixed(2));
        tPRI.push(parseFloat(item.PRI).toFixed(2));
        tPRD.push(parseFloat(item.PRD).toFixed(2));
        tPT.push(parseFloat(item.PT).toFixed(2));
        tPVEM.push(parseFloat(item.PVEM).toFixed(2));
        tMC.push(parseFloat(item.MC).toFixed(2));
        tMORENA.push(parseFloat(item.MORENA).toFixed(2));
      }
    });

    const objPart = {
      Categorias,
      yAxis: {
        title: {
          text: "Participación",
        },
      },
      xAxis: {
        categories: Categorias,
        accessibility: {
          rangeDescription: "Range: 2012 to 2021",
        },
      },
      series: [
        {
          name: "Participación",
          data: PartData.map(Number),
        },
        {
          name: "Abstención",
          data: AbstData.map(Number),
        },
      ],
    };
    setChartPart(objPart);

    console.log("=>part: ", objPart);
    setLoading(false);
    //setshowMap(true);
  }, [filtro]);

  useEffect(() => {
    if (page1 !== null) {
      //if (page2 !== null) {
      if (page3 !== null) {
        if (page4 !== null) {
          if (page5 !== null) {
            if (page6 !== null) {
              // if (page7 !== null) {
              //   if (page8 !== null) {
              //     if (page9 !== null) {
              //       if (page10 !== null) {
              // if (page11 !== null) {
              //   if (page12 !== null) {
              //     if (page13 !== null) {
              //       if (page14 !== null) {
              //         if (page15 !== null) {
              //           if (page16 !== null) {
              //             if (page17 !== null) {
              //               if (page18 !== null) {
              //                 if (page19 !== null) {
              //                   if (page20 !== null) {
              //                     if (page21 !== null) {
              //                       if (page22 !== null) {
              const imgData1 = page1.toDataURL("image/png");
              //const imgData2 = page2.toDataURL("image/png");
              const imgData3 = page3.toDataURL("image/png");
              //const imgData4 = page4.toDataURL("image/png");
              //const imgData5 = page5.toDataURL("image/png");
              // const imgData6 = page6.toDataURL("image/png");
              // const imgData7 = page7.toDataURL("image/png");
              // const imgData8 = page8.toDataURL("image/png");
              // const imgData9 =
              //   page9.toDataURL("image/png");
              // const imgData10 =
              //   page10.toDataURL("image/png");
              // const imgData11 =
              //   page11.toDataURL("image/png");
              // const imgData12 =
              //   page12.toDataURL("image/png");
              // const imgData13 =
              //   page13.toDataURL("image/png");
              // const imgData14 =
              //   page14.toDataURL("image/png");
              // const imgData15 =
              //   page15.toDataURL("image/png");
              // const imgData16 =
              //   page16.toDataURL("image/png");
              // const imgData17 =
              //   page17.toDataURL("image/png");
              // const imgData18 =
              //   page18.toDataURL("image/png");
              // const imgData19 =
              //   page19.toDataURL("image/png");
              // const imgData20 =
              //   page20.toDataURL("image/png");
              // const imgData21 =
              //   page21.toDataURL("image/png");
              // const imgData22 =
              //   page22.toDataURL("image/png");

              console.log(
                "=>Img1XX canvas.width, canvas.height",
                `${page1.width}-200, ${page1.height - 250}`
              );

              const pdf = new jsPDF({
                orientation: "p", // landscape
                unit: "pt", // points, pixels won't work properly
                format: [page1.width - 200, page1.height - 250], // set needed dimensions for any element
              });
              pdf.addImage(imgData1, "PNG", 0, 0);

              // pdf.addPage();
              // pdf.addImage(imgData2, "PNG", 0, 0);

              pdf.addPage();
              pdf.addImage(imgData3, "PNG", 0, 0);

              // pdf.addPage();
              // pdf.addImage(imgData4, "PNG", 0, 0);

              // pdf.addPage();
              // pdf.addImage(imgData5, "PNG", 0, 0);

              // pdf.addPage();
              // pdf.addImage(imgData6, "PNG", 0, 0);

              // pdf.addPage();
              // pdf.addImage(imgData7, "PNG", 0, 0);

              // pdf.addPage();
              // pdf.addImage(imgData8, "PNG", 0, 0);

              // pdf.addPage();
              // pdf.addImage(
              //   imgData9,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData10,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData11,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData12,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData13,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData14,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData15,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData16,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData17,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData18,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData19,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData20,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData21,
              //   "PNG",
              //   0,
              //   0
              // );

              // pdf.addPage();
              // pdf.addImage(
              //   imgData22,
              //   "PNG",
              //   0,
              //   0
              // );

              console.log("=>Save PDF");
              pdf.save(filtro.label);
              setPrintLoading(false);
              setPage1(null);
              //setPage2(null);
              setPage3(null);
              setPage4(null);
              setPage5(null);
              setPage6(null);
              setPage7(null);
              setPage8(null);
              setPage9(null);
              setPage10(null);
              setPage11(null);
              setPage12(null);
              setPage13(null);
              setPage14(null);
              setPage15(null);
              setPage16(null);
              setPage17(null);
              setPage18(null);
              setPage19(null);
              setPage20(null);
              setPage21(null);
              setPage22(null);
            }
          }
        }
      }
    }
    //       }
    //     }
    //   }
    // }
    // }
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }, [
    page1,
    page2,
    page3,
    page4,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
    page15,
    page16,
    page17,
    page18,
    page19,
    page20,
    page21,
    page22,
    filtro,
  ]);

  const sendResults = (item) => {
    //setshowMap(false);
    setFilter(item);
    console.log("=>item: ", item);
  };

  const printDocument = () => {
    setPrintLoading(true);
    console.log("=>Begin Print");
    const divpage1 = document.getElementById("divToPrint1");
    //const divpage2 = document.getElementById("divToPrint2");
    const divpage3 = document.getElementById("divToPrint3");
    const divpage4 = document.getElementById("divToPrint4");
    const divpage5 = document.getElementById("divToPrint5");
    const divpage6 = document.getElementById("divToPrint6");
    // const divpage7 = document.getElementById("divToPrint7");
    // const divpage8 = document.getElementById("divToPrint8");
    // const divpage9 = document.getElementById("divToPrint9");
    // const divpage10 = document.getElementById("divToPrint10");
    const divpage11 = document.getElementById("divToPrint11");
    const divpage12 = document.getElementById("divToPrint12");
    const divpage13 = document.getElementById("divToPrint13");
    const divpage14 = document.getElementById("divToPrint14");
    const divpage15 = document.getElementById("divToPrint15");
    const divpage16 = document.getElementById("divToPrint16");
    const divpage17 = document.getElementById("divToPrint17");
    const divpage18 = document.getElementById("divToPrint18");
    const divpage19 = document.getElementById("divToPrint19");
    const divpage20 = document.getElementById("divToPrint20");
    const divpage21 = document.getElementById("divToPrint21");
    const divpage22 = document.getElementById("divToPrint22");

    html2canvas(divpage1).then((canvas) => {
      //const imgData = canvas.toDataURL("image/png");
      setPage1(canvas);
      console.log("=>Ready Page1");
    });

    // html2canvas(divpage2).then((canvas) => {
    //   //const imgData = canvas.toDataURL("image/png");

    //   setPage2(canvas);
    //   console.log("=>Ready Page2");
    // });

    html2canvas(divpage3).then((canvas) => {
      //const imgData = canvas.toDataURL("image/png");

      setPage3(canvas);
      console.log("=>Ready Page3");
    });

    html2canvas(divpage4).then((canvas) => {
      //const imgData = canvas.toDataURL("image/png");

      setPage4(canvas);
      console.log("=>Ready Page4");
    });

    html2canvas(divpage5).then((canvas) => {
      //const imgData = canvas.toDataURL("image/png");

      setPage5(canvas);
      console.log("=>Ready Page5");
    });
    html2canvas(divpage6).then((canvas) => {
      //const imgData = canvas.toDataURL("image/png");

      setPage6(canvas);
      console.log("=>Ready Page6");
    });
    // html2canvas(divpage7).then((canvas) => {
    //   //const imgData = canvas.toDataURL("image/png");

    //   setPage7(canvas);
    //   console.log("=>Ready Page7");
    // });

    // html2canvas(divpage8).then((canvas) => {
    //   //const imgData = canvas.toDataURL("image/png");

    //   setPage8(canvas);
    //   console.log("=>Ready Page8");
    // });

    // html2canvas(divpage9).then((canvas) => {
    //   //const imgData = canvas.toDataURL("image/png");

    //   setPage9(canvas);
    //   console.log("=>Ready Page9");
    // });

    // html2canvas(divpage10).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page10");
    // });
    // html2canvas(divpage11).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page11");
    // });
    // html2canvas(divpage12).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page12");
    // });
    // html2canvas(divpage13).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page13");
    // });
    // html2canvas(divpage14).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page14");
    // });
    // html2canvas(divpage15).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page15");
    // });
    // html2canvas(divpage16).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page16");
    // });
    // html2canvas(divpage17).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page107");
    // });
    // html2canvas(divpage18).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page18");
    // });
    // html2canvas(divpage19).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page19");
    // });
    // html2canvas(divpage20).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page20");
    // });
    // html2canvas(divpage21).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page21");
    // });
    // html2canvas(divpage22).then((canvas) => {
    //   setPage10(canvas);
    //   console.log("=>Ready Page22");
    // });
  };

  return (
    <>
      <Notifications></Notifications>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm="12" md="12" lg="12" className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                <CardContent className="p-0">
                  <Container>
                    <Box>
                      <Select
                        options={availableFilters}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={filtro}
                        onChange={(e) => sendResults(e)}
                        placeholder="Seleccione una opción"
                        isLoading={isLoading}
                      />
                      {!isPrintLoading ? (
                        <button onClick={() => printDocument()}>
                          Exportar
                        </button>
                      ) : (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#0b3cae"
                          loading={true}
                        />
                      )}
                      <br />

                      <div
                        id="divToPrint1"
                        style={{ paddingLeft: "5px", paddingTop: "10px" }}
                      >
                        <br />
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={7} md={7}>
                            <Card variant="outlined">
                              {!isLoading && dataRevocacion !== null && (
                                <BarrasDobles dataInfo={dataRevocacion} />
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                          <Grid item xs={5} md={5}>
                            <Card variant="outlined">
                              {!isLoading && dataRevocacion !== null && (
                                <BarraMeta dataInfo={dataRevocacion} />
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          {!isLoading ? (
                            <table id="historico" style={{ width: "1100px" }}>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "20%",
                                  }}
                                >
                                  Elecciones 2021
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPAN}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPRI}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPRD}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPT}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPVEM}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoMC}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoMORENA}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoNA_Gto}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>Total</th>
                                <th>LNOM</th>
                                <th>PART</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>DIFF VOT</th>
                              </tr>
                              {ultimoHistorico.length > 0 &&
                                ultimoHistorico.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.MUNICIPIO_DATA}</td>
                                      {item.PriFZA.toUpperCase() === "PAN" ? (
                                        <td className="pan">
                                          {numberWithCommas(item.PAN)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.PAN)}</td>
                                      )}
                                      {item.PriFZA.toUpperCase() === "PRI" ? (
                                        <td className="pri">
                                          {numberWithCommas(item.PRI)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.PRI)}</td>
                                      )}
                                      {item.PriFZA.toUpperCase() === "PRD" ? (
                                        <td className="prd">
                                          {numberWithCommas(item.PRD)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.PRD)}</td>
                                      )}
                                      {item.PriFZA.toUpperCase() === "PT" ? (
                                        <td className="pt">
                                          {numberWithCommas(item.PT)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.PT)}</td>
                                      )}
                                      {item.PriFZA.toUpperCase() === "PVEM" ? (
                                        <td className="pvem">
                                          {numberWithCommas(item.PVEM)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.PVEM)}</td>
                                      )}
                                      {item.PriFZA.toUpperCase() === "MC" ? (
                                        <td className="mc">
                                          {numberWithCommas(item.MC)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.MC)}</td>
                                      )}
                                      {item.PriFZA.toUpperCase() ===
                                      "MORENA" ? (
                                        <td className="morena">
                                          {numberWithCommas(item.MORENA)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.MORENA)}</td>
                                      )}
                                      {item.PriFZA.toUpperCase() ===
                                      "NA_GTO" ? (
                                        <td className="na_gto">
                                          {numberWithCommas(item.NA_Gto)}
                                        </td>
                                      ) : (
                                        <td>{numberWithCommas(item.NA_Gto)}</td>
                                      )}

                                      <td>
                                        {numberWithCommas(
                                          item.TOTAL_VOTOS_CALCULADO
                                        )}
                                      </td>
                                      <td>
                                        {numberWithCommas(item.LISTA_NOMINAL)}
                                      </td>
                                      <td>
                                        {numberWithCommas(
                                          parseFloat(item.PP).toFixed(2)
                                        )}{" "}
                                        %
                                      </td>
                                      <td>{icoPartido(item.PriFZA)}</td>
                                      <td>{icoPartido(item.SegFZA)}</td>
                                      <td>{`${numberWithCommas(
                                        item.DIFF_1FZA
                                      )} - ${parseFloat(
                                        item.PORCENTAJE_DIFF
                                      ).toFixed(2)} %`}</td>
                                    </tr>
                                  );
                                })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && infoSecciones !== null && (
                                <table id="historico" style={{ width: "100%" }}>
                                  <th colSpan="4">Numeralia 2021</th>
                                  <tr>
                                    <td>
                                      <b>Secciones:</b>
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        infoSecciones.Secciones
                                      )}
                                    </td>
                                    <td>
                                      <b>LNom:</b>
                                    </td>
                                    <td>
                                      {numberWithCommas(infoSecciones.lnom)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Urbano:</b>
                                    </td>
                                    <td>
                                      {numberWithCommas(infoSecciones.Urbanas)}{" "}
                                    </td>
                                    <td>
                                      <b>LNom </b>
                                    </td>
                                    <td>
                                      {numberWithCommas(infoSecciones.lnomUrb)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Mixto:</b>
                                    </td>
                                    <td>
                                      {numberWithCommas(infoSecciones.Mixtas)}{" "}
                                    </td>
                                    <td>
                                      <b>LNom </b>
                                    </td>
                                    <td>
                                      {numberWithCommas(infoSecciones.lnomMix)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Rural:</b>
                                    </td>
                                    <td>
                                      {numberWithCommas(infoSecciones.Rurales)}{" "}
                                    </td>
                                    <td>
                                      <b>LNom </b>
                                    </td>
                                    <td>
                                      {numberWithCommas(infoSecciones.lnomRur)}
                                    </td>
                                  </tr>
                                </table>
                              )}
                              {infoSecciones !== null && (
                                <table id="historico" style={{ width: "100%" }}>
                                  <th colSpan="4">Proyección 2024</th>
                                  <tr>
                                    <td>
                                      <b>Lnom Estatal 2022:</b>
                                    </td>
                                    <td>
                                      <strong>{`${numberWithCommas(
                                        "4490028"
                                      )}`}</strong>
                                    </td>
                                    <td>
                                      <b>Lnom Estatal 2024:</b>
                                    </td>
                                    <td>
                                      <strong>
                                        {numberWithCommas("4752683")}
                                      </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Lnom May 2022:</b>
                                    </td>
                                    <td>
                                      <strong>{`${numberWithCommas(
                                        meta24.LnomMay22
                                      )}`}</strong>
                                      &nbsp;
                                      {`${numberWithCommas(
                                        meta24.PorcLnom22.toFixed(2)
                                      )}%`}
                                    </td>
                                    <td>
                                      <b>Lnom Proy 2024:</b>
                                    </td>
                                    <td>
                                      <strong>
                                        {numberWithCommas(meta24.LnomProy24)}
                                      </strong>
                                      &nbsp;
                                      {`${numberWithCommas(
                                        (
                                          100 *
                                          (meta24.LnomProy24 / 4752683)
                                        ).toFixed(2)
                                      )}%`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Votación Max Gobernador</b>
                                    </td>
                                    <td>
                                      <strong>
                                        {numberWithCommas(meta24.VotoMaximoPan)}{" "}
                                      </strong>
                                    </td>
                                    <td>
                                      <b>Año Voto Máximo </b>
                                    </td>
                                    <td>
                                      <strong>{meta24.YearVotoMaximo} </strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Meta Votos 2024:</b>
                                    </td>
                                    <td>
                                      <strong>
                                        {numberWithCommas(
                                          meta24.MetaVotacion24
                                        )}
                                      </strong>
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </table>
                              )}

                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && infoHist !== null && (
                                <table id="historico" style={{ width: "100%" }}>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      Partido
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Vot. Prom
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Vot. Hist
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Vot. Mín
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Hist Part
                                    </th>
                                  </tr>

                                  <tr>
                                    <td>
                                      <img
                                        alt="logo"
                                        className="img-responsive"
                                        src={icoPAN}
                                        width="30"
                                        height="30"
                                      />
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        parseFloat(infoHist.PANVProm).toFixed(0)
                                      )}
                                    </td>
                                    <td>{numberWithCommas(infoHist.PANMax)}</td>
                                    <td>{numberWithCommas(infoHist.PANMin)}</td>
                                    <td>
                                      {`${parseFloat(infoHist.PANHist).toFixed(
                                        2
                                      )} %`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <img
                                        alt="logo"
                                        className="img-responsive"
                                        src={icoPRI}
                                        width="30"
                                        height="30"
                                      />
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        parseFloat(infoHist.PRIVProm).toFixed(0)
                                      )}
                                    </td>
                                    <td>{numberWithCommas(infoHist.PRIMax)}</td>
                                    <td>{numberWithCommas(infoHist.PRIMin)}</td>
                                    <td>
                                      {`${parseFloat(infoHist.PRIHist).toFixed(
                                        2
                                      )} %`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <img
                                        alt="logo"
                                        className="img-responsive"
                                        src={icoPRD}
                                        width="30"
                                        height="30"
                                      />
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        parseFloat(infoHist.PRDVProm).toFixed(0)
                                      )}
                                    </td>
                                    <td>{numberWithCommas(infoHist.PRDMax)}</td>
                                    <td>{numberWithCommas(infoHist.PRDMin)}</td>
                                    <td>
                                      {`${parseFloat(infoHist.PRDHist).toFixed(
                                        2
                                      )} %`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <img
                                        alt="logo"
                                        className="img-responsive"
                                        src={icoPT}
                                        width="30"
                                        height="30"
                                      />
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        parseFloat(infoHist.PTVProm).toFixed(0)
                                      )}
                                    </td>
                                    <td>{numberWithCommas(infoHist.PTMax)}</td>
                                    <td>{numberWithCommas(infoHist.PTMin)}</td>
                                    <td>
                                      {`${parseFloat(infoHist.PTHist).toFixed(
                                        2
                                      )} %`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <img
                                        alt="logo"
                                        className="img-responsive"
                                        src={icoPVEM}
                                        width="30"
                                        height="30"
                                      />
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        parseFloat(infoHist.PVEMVProm).toFixed(
                                          0
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {numberWithCommas(infoHist.PVEMMax)}
                                    </td>
                                    <td>
                                      {numberWithCommas(infoHist.PVEMMin)}
                                    </td>
                                    <td>
                                      {`${parseFloat(infoHist.PVEMHist).toFixed(
                                        2
                                      )} %`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <img
                                        alt="logo"
                                        className="img-responsive"
                                        src={icoMC}
                                        width="30"
                                        height="30"
                                      />
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        parseFloat(infoHist.MCVProm).toFixed(0)
                                      )}
                                    </td>
                                    <td>{numberWithCommas(infoHist.MCMax)}</td>
                                    <td>{numberWithCommas(infoHist.MCMin)}</td>
                                    <td>
                                      {`${parseFloat(infoHist.MCHist).toFixed(
                                        2
                                      )} %`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <img
                                        alt="logo"
                                        className="img-responsive"
                                        src={icoMORENA}
                                        width="30"
                                        height="30"
                                      />
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        parseFloat(
                                          infoHist.MORENAVProm
                                        ).toFixed(0)
                                      )}
                                    </td>
                                    <td>
                                      {numberWithCommas(infoHist.MORENAMax)}
                                    </td>
                                    <td>
                                      {numberWithCommas(infoHist.MORENAMin)}
                                    </td>
                                    <td>
                                      {`${parseFloat(
                                        infoHist.MORENAHist
                                      ).toFixed(2)} %`}
                                    </td>
                                  </tr>
                                </table>
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center">
                          {/* <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && infBloqueCDM !== null && (
                                <table id="historico" style={{ width: "100%" }}>
                                  <tr>
                                    <th
                                      colspan="6"
                                      style={{ textAlign: "center" }}
                                    >
                                      Bloques de Paridad RNM
                                    </th>
                                  </tr>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      Bloque
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Número en Bloque
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Hombres
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Mujeres
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Total
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Género
                                    </th>
                                  </tr>

                                  <tr>
                                    <td>{infBloqueCDM.Bloque}</td>
                                    <td>{infBloqueCDM.NumeroBloque}</td>
                                    <td>
                                      {numberWithCommas(infBloqueCDM.HOMBRES)}
                                    </td>
                                    <td>
                                      {numberWithCommas(infBloqueCDM.MUJERES)}
                                    </td>
                                    <td>
                                      {numberWithCommas(infBloqueCDM.TOTAL)}
                                    </td>
                                    <td>
                                      <strong>{infBloqueCDM.Genero}</strong>
                                    </td>
                                  </tr>
                                </table>
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid> */}
                          <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && infBloqueCDM !== null && (
                                <table id="historico" style={{ width: "100%" }}>
                                  <tr>
                                    <th
                                      colspan="6"
                                      style={{ textAlign: "center" }}
                                    >
                                      Militantes 30 Septiembre 2022
                                    </th>
                                  </tr>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      Militantes
                                    </th>

                                    <th style={{ textAlign: "center" }}>
                                      Hombres
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Mujeres
                                    </th>
                                  </tr>

                                  <tr>
                                    <td>
                                      {numberWithCommas(
                                        militantesStats.Militantes
                                      )}
                                    </td>
                                    <td>
                                      {`${numberWithCommas(
                                        militantesStats.Hombres
                                      )}  (${(
                                        100 *
                                        (militantesStats.Hombres /
                                          militantesStats.Militantes)
                                      ).toFixed(2)} %)`}
                                    </td>
                                    <td>
                                      {`${numberWithCommas(
                                        militantesStats.Mujeres
                                      )}  (${(
                                        100 *
                                        (militantesStats.Mujeres /
                                          militantesStats.Militantes)
                                      ).toFixed(2)} %)`}
                                    </td>
                                  </tr>
                                </table>
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                          <Grid item xs={6} md={6}></Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && (
                                <CardContent>
                                  {chartTendencia !== null ? (
                                    <TimeLine
                                      title="Tendencia de Votación"
                                      subtitle="Elección de Ayuntamiento"
                                      yAxis={chartTendencia.yAxis}
                                      xAxis={chartTendencia.xAxis}
                                      series={chartTendencia.series}
                                    />
                                  ) : (
                                    <CircleSpinner
                                      className="text-center"
                                      size={30}
                                      color="#0b3cae"
                                      loading={true}
                                    />
                                  )}
                                </CardContent>
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && (
                                <CardContent>
                                  {chartPart !== null ? (
                                    <TimeLine
                                      title="Participación"
                                      subtitle="Elección de Ayuntamiento"
                                      yAxis={chartPart.yAxis}
                                      xAxis={chartPart.xAxis}
                                      series={chartPart.series}
                                    />
                                  ) : (
                                    <CircleSpinner
                                      className="text-center"
                                      size={30}
                                      color="#0b3cae"
                                      loading={true}
                                    />
                                  )}
                                </CardContent>
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                      <div
                        id="divToPrint3"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <br />
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && infoSecciones !== null && (
                                <table id="historico" style={{ width: "100%" }}>
                                  <th colSpan="4">
                                    Población Censo INEGI 2020
                                  </th>
                                  <tr>
                                    <td>
                                      <b>Población:</b>
                                    </td>
                                    <td>
                                      <b>Población Hombres:</b>
                                    </td>
                                    <td>
                                      <b>Población Mujeres:</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {numberWithCommas(censo20.Poblacion)}
                                    </td>
                                    <td>
                                      {numberWithCommas(censo20.PobHombres)}
                                      &nbsp;&nbsp;&nbsp;
                                      {`${numberWithCommas(
                                        100 *
                                          (
                                            censo20.PobHombres /
                                            censo20.Poblacion
                                          ).toFixed(2)
                                      )}%`}
                                    </td>
                                    <td>
                                      {numberWithCommas(censo20.PobMujeres)}
                                      &nbsp;&nbsp;&nbsp;
                                      {`${numberWithCommas(
                                        100 *
                                          (
                                            censo20.PobMujeres /
                                            censo20.Poblacion
                                          ).toFixed(2)
                                      )}%`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Pob 18 a 24 años</b>
                                    </td>
                                    <td>
                                      <b>Pob 26 a 59 años</b>
                                    </td>
                                    <td>
                                      <b>Pob 60 y más</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {numberWithCommas(censo20.Pob18a24)}
                                    </td>
                                    <td>
                                      {numberWithCommas(censo20.Pob26a59)}
                                    </td>
                                    <td>
                                      {numberWithCommas(censo20.Pob60ymas)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <b>Localidades Mayores 100 Habitantes</b>
                                    </td>
                                    <td>
                                      {numberWithCommas(
                                        censo20.LocalidadesMayores100Hab
                                      )}
                                    </td>
                                  </tr>
                                </table>
                              )}

                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Card variant="outlined">
                              {!isLoading && censo20 !== null && (
                                <table
                                  id="historico"
                                  style={{ width: "600px" }}
                                >
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      Top Población
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Nombre Localidad
                                    </th>
                                  </tr>

                                  <tr>
                                    <td>
                                      {numberWithCommas(
                                        censo20.LocalidadMayorPoblacionTop1Pob
                                      )}
                                    </td>
                                    <td>
                                      {censo20.LocalidadMayorPoblacionTop1}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {numberWithCommas(
                                        censo20.LocalidadMayorPoblacionTop2Pob
                                      )}
                                    </td>
                                    <td>
                                      {censo20.LocalidadMayorPoblacionTop2}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {numberWithCommas(
                                        censo20.LocalidadMayorPoblacionTop3Pob
                                      )}
                                    </td>
                                    <td>
                                      {censo20.LocalidadMayorPoblacionTop3}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {numberWithCommas(
                                        censo20.LocalidadMayorPoblacionTop4Pob
                                      )}
                                    </td>
                                    <td>
                                      {censo20.LocalidadMayorPoblacionTop4}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {numberWithCommas(
                                        censo20.LocalidadMayorPoblacionTop5Pob
                                      )}
                                    </td>
                                    <td>
                                      {censo20.LocalidadMayorPoblacionTop5}
                                    </td>
                                  </tr>
                                </table>
                              )}
                              {isLoading && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#0b3cae"
                                  loading={true}
                                />
                              )}
                            </Card>
                          </Grid>
                        </Grid>
                        {/* </div> */}
                        {/* <div
                        id="divToPrint2"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      > */}

                        <Grid container direction="row" justify="center">
                          <br />
                          {ultimoHistoricoDetalle.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "center",
                                    width: "20%",
                                  }}
                                >
                                  Histórico
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPAN}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPRI}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPRD}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPT}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoPVEM}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoMC}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>
                                  <img
                                    alt="logo"
                                    className="img-responsive"
                                    src={icoMORENA}
                                    width="30"
                                    height="30"
                                  />
                                </th>
                                <th>Total</th>
                                <th>LNOM</th>
                                <th>PART</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>DIFF VOT</th>
                              </tr>
                              {ultimoHistoricoDetalle.length > 0 &&
                                ultimoHistoricoDetalle
                                  .slice(0, 16)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <b>{`${item.MUNICIPIO_DATA}`}</b>
                                        </td>
                                        {item.PriFZA.toUpperCase() === "PAN" ? (
                                          <td className="pan">
                                            {numberWithCommas(item.PAN)}
                                          </td>
                                        ) : (
                                          <td>{numberWithCommas(item.PAN)}</td>
                                        )}
                                        {item.PriFZA.toUpperCase() === "PRI" ? (
                                          <td className="pri">
                                            {numberWithCommas(item.PRI)}
                                          </td>
                                        ) : (
                                          <td>{numberWithCommas(item.PRI)}</td>
                                        )}
                                        {item.PriFZA.toUpperCase() === "PRD" ? (
                                          <td className="prd">
                                            {numberWithCommas(item.PRD)}
                                          </td>
                                        ) : (
                                          <td>{numberWithCommas(item.PRD)}</td>
                                        )}
                                        {item.PriFZA.toUpperCase() === "PT" ? (
                                          <td className="pt">
                                            {numberWithCommas(item.PT)}
                                          </td>
                                        ) : (
                                          <td>{numberWithCommas(item.PT)}</td>
                                        )}
                                        {item.PriFZA.toUpperCase() ===
                                        "PVEM" ? (
                                          <td className="pvem">
                                            {numberWithCommas(item.PVEM)}
                                          </td>
                                        ) : (
                                          <td>{numberWithCommas(item.PVEM)}</td>
                                        )}
                                        {item.PriFZA.toUpperCase() === "MC" ? (
                                          <td className="mc">
                                            {numberWithCommas(item.MC)}
                                          </td>
                                        ) : (
                                          <td>{numberWithCommas(item.MC)}</td>
                                        )}
                                        {item.PriFZA.toUpperCase() ===
                                        "MORENA" ? (
                                          <td className="morena">
                                            {numberWithCommas(item.MORENA)}
                                          </td>
                                        ) : (
                                          <td>
                                            {numberWithCommas(item.MORENA)}
                                          </td>
                                        )}
                                        <td>
                                          {numberWithCommas(
                                            item.TOTAL_VOTOS_CALCULADO
                                          )}
                                        </td>
                                        <td>
                                          {numberWithCommas(item.LISTA_NOMINAL)}
                                        </td>
                                        <td>
                                          {numberWithCommas(
                                            parseFloat(item.PP).toFixed(2)
                                          )}{" "}
                                          %
                                        </td>
                                        <td>{icoPartido(item.PriFZA)}</td>
                                        <td>{icoPartido(item.SegFZA)}</td>
                                        <td>{`${numberWithCommas(
                                          item.DIFF_1FZA
                                        )} - ${parseFloat(
                                          item.PORCENTAJE_DIFF
                                        ).toFixed(2)} %`}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(0, fr1)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td className={item.Renta24Proy}>
                                          {item.Renta24Proy}
                                        </td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint4"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr1, fr2)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint5"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr2, fr3)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint6"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr3, fr4)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint7"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr3, fr4)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint8"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr4, fr5)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint9"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr5, fr6)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint10"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr6, fr7)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint11"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr7, fr8)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint12"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr8, fr9)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint13"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr9, fr10)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint14"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr10, fr11)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint15"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr11, fr12)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint16"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr12, fr13)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint17"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr13, fr14)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint18"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr14, fr15)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint19"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr15, fr16)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint20"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr16, fr17)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint21"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr17, fr18)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                      <div
                        id="divToPrint22"
                        style={{ paddingLeft: "10px", paddingTop: "10px" }}
                      >
                        <Grid container direction="row" justify="center">
                          <br />
                          {metaSeccion24.length > 0 && !isLoading ? (
                            <table id="historico" style={{ width: "1350px" }}>
                              <tr>
                                <th>Sección</th>
                                <th>Participación</th>
                                <th>Lnom24</th>
                                <th>Meta24</th>

                                <th>Rentabilidad</th>
                                <th>Tendencia</th>
                                <th>PAN</th>
                                <th>1FZA</th>
                                <th>2FZA</th>
                                <th>1FZA Vot</th>
                                <th>2FZA Vot</th>
                              </tr>
                              {metaSeccion24.length > 0 &&
                                metaSeccion24
                                  .slice(fr18, fr19)
                                  .map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.SECCION}</td>
                                        <td>
                                          {`${numberWithCommas(
                                            parseFloat(
                                              item.Participacion
                                            ).toFixed(2)
                                          )} %`}
                                        </td>
                                        <td>{numberWithCommas(item.Lnom24)}</td>
                                        <td>{numberWithCommas(item.Meta24)}</td>

                                        <td>{item.Renta24Proy}</td>
                                        <td>{item.TipoTendencia}</td>
                                        <td> {numberWithCommas(item.PAN)}</td>
                                        <td
                                          className={
                                            item.PrimFza.toUpperCase() === "PAN"
                                              ? "pan"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRI"
                                              ? "pri"
                                              : item.PrimFza.toUpperCase() ===
                                                "MORENA"
                                              ? "morena"
                                              : item.PrimFza.toUpperCase() ===
                                                "PVEM"
                                              ? "pvem"
                                              : item.PrimFza.toUpperCase() ===
                                                "PRD"
                                              ? "prd"
                                              : item.PrimFza.toUpperCase() ===
                                                "RSP"
                                              ? "rsp"
                                              : item.PrimFza.toUpperCase() ===
                                                "MC"
                                              ? "mc"
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {item.PrimFza}
                                        </td>
                                        <td> {item.SegFza}</td>
                                        <td> {item.PrimFza_VOTOS}</td>
                                        <td> {item.SegFza_VOTOS}</td>
                                      </tr>
                                    );
                                  })}
                            </table>
                          ) : (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#0b3cae"
                              loading={true}
                            />
                          )}
                        </Grid>
                      </div>
                    </Box>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
        {/* <div
          id="divToPrint4"
          style={{ paddingLeft: "10px", paddingTop: "10px" }}
        >
          {showMap && (
            <Map
              idMunicipio={filtro.value}
              zoom={12}
              data={municipios_geojson}
            />
          )}
        </div> */}
      </Container>
    </>
  );
};
export default Analisis;
