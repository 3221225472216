import React, { useEffect, useState } from "react";
import {
  Autocomplete, TextField, FormControl
} from "@mui/material";
import { getVars } from "./../../utils/GlobalStorage";
import ApiExec from "../../utils/ApiExec";
import Notifications, { notify } from "react-notify-toast";
import { MetroSpinner } from "react-spinners-kit";

const AutoComplete = ({ title, options, name, api, params=[], fil, error, helperText, onChange, reset, value={} }) => {
  const token = getVars("Token");
  const _title = title !== undefined ? title : "Sin título";
  const [_options, setOptions] = useState(Array.isArray(options)
    ? options
    : [value]);
  const [loading, setLoading] = useState(false);

  const catalogos = (filtros) => {
    ApiExec({"filtered":filtros}, api, token.access_token, {})
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setOptions(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          notify.show(
            `Error ${api}: ${res.message}`,
            "error",
            5000,
            "#FFFFFF"
          );
        }
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error ${api}! ${e.message}`, "custom", 5000, {
          background: "#0E1717",
          text: "#FFFFFF",
        });
      });

  }

  useEffect(() => {
    setLoading(reset)
// eslint-disable-next-line
  }, [reset]);

  useEffect(() => {
// eslint-disable-next-line
  }, [_options]);

  const handleOnChange = (e) => {
    let filtros = params?[...params]:[];
    let datoSearch = e.toUpperCase()

    if(datoSearch.length >2){
      filtros.push({"id":fil,"value":datoSearch,"filter":"like"})
      let validation = _options.filter((item)=>item.label.toUpperCase() === datoSearch)
      if(validation.length === 1){
        onChange(validation[0])
      }else{
        setOptions([])
        catalogos(filtros)
      }
    }
    
  }; 

  
  return (
    <>
      {
        loading ?
          (<MetroSpinner
            size={30}
            color="#2285E7"
            loading={loading}
          />)
          : (
            <FormControl>
              <Notifications />
              <Autocomplete
                id={title}
                name={name}
                options={_options}
                inputValue={value.label}
                onInputChange={(event, newInputValue) => {
                  handleOnChange(newInputValue)
                }}
                getOptionLabel={(_options) => _options.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    id={title}
                    name={name}
                    label={_title}
                    placeholder=""
                    error={error}
                    helperText={helperText}
                  />
                )}
              />
            </FormControl>

          )}
    </>
  );
};
export default AutoComplete;
