import React, { useState } from "react";
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import { Card, CardBody, Button, Input, FormGroup } from "reactstrap";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import "../../assets/css/Veda/SiteMD.css";
import { dataCurp } from "../../data/interfaces/Curp";
import { setVars } from "../../utils/GlobalStorage";

const CurpAccess = () => {
  const url_curp_validation =
    "https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28";
  const history = useHistory();
  const [Curp, setCurp] = useState("");
  const [errorForm, setErrorForm] = useState("");
  const validationField = str_curp => {
    setCurp(str_curp);
    const pattern = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
    if (!pattern.test(str_curp)) {
      setErrorForm("Ingrese una curp válida");
    } else {
      setErrorForm("");
    }
  };

  const validationCurp = e => {
    e.preventDefault();
    const url = `${url_curp_validation}/${Curp.toUpperCase()}`;
    if (Curp.length === 18) {
      fetch(url)
        .then(res => res.json())
        .then(response => {
          if (response.Mensaje === "OK") {
            setVars("dataCurp", {
              valid: true,
              data: response.Resultado
            });
            history.push("/cuestionario");
          } else {
            setVars("dataCurp", {
              valid: false,
              data: dataCurp
            });
            swal({
              title: "La CURP que ingresó no es válida",
              text: "Revise los caracteres e intente de nuevo",
              icon: "warning",
              dangerMode: true,
              allowOutsideClick: false,
              closeOnEsc: false
            });
          }
        })
        .catch(e => {
          setVars("dataCurp", {
            valid: false,
            data: dataCurp
          });
          swal({
            title: "Hubo un error, intente nuevamente",
            text: "Error: " + e.message,
            icon: "warning",
            dangerMode: true,
            allowOutsideClick: false,
            closeOnEsc: false
          });
        });
    }
  };

  return (
    <>
      <Header></Header>
      <div id="render-body" className="pt-6 grey lighten-5">
        <div className="pt-5 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                <Card>
                  <h4 className="card-header gto-blue-darken-2 white-text text-center p-4">
                    Ingresa tu CURP
                  </h4>
                  <CardBody>
                    <div className="container">
                      <form
                        onSubmit={e => validationCurp(e)}
                        className="form-horizontal"
                      >
                        <FormGroup>
                          <Input
                            placeholder="Introducir CURP"
                            autoFocus="autofocus"
                            className="form-control"
                            data-val="true"
                            data-val-required="El campo curp es requerido."
                            id="Curp"
                            name="Curp"
                            type="text"
                            value={Curp}
                            onKeyPress={e => {
                              e.target.keyCode === 13 && e.preventDefault();
                            }}
                            onChange={e => {
                              e.preventDefault();
                              validationField(e.target.value.toUpperCase());
                            }}
                          />
                          <div className="errorFields">
                            <small>{errorForm}</small>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Button className="btn my-4 orange darken-2 btn-block">
                            Validar
                          </Button>
                        </FormGroup>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default CurpAccess;
