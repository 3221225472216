const getDataMenu = ( Menu, menus ) => {
    let json = {};
    menus.forEach((prop) => {
        if (prop.idMenu === Menu) {
                json = {
                    Ver: prop.Ver,
                    Agregar: prop.Agregar?prop.Agregar:0,
                    Editar: prop.Editar?prop.Editar:0,
                    Eliminar: prop.Eliminar?prop.Eliminar:0,
                    Exportar: prop.Exportar?prop.Exportar:0,
                    Propuesta: prop.Propuesta?prop.Propuesta:0,
                    Seguimiento: prop.Seguimiento?prop.Seguimiento:0,
                    Capturista:prop.Capturista?prop.Capturista:0,
                    ViewAll: prop.ViewAll?prop.ViewAll:0,
                    Electoral: prop.Electoral?prop.Electoral:0,
                    Territorial: prop.Territorial?prop.Territorial:0,
                    Social:prop.Social?prop.Social:0,
                };
        } else if(prop.views) {
            prop.views.forEach((item) => {
                if (item.idMenu === Menu) {
                    json = {
                        Ver: item.Ver?item.Ver:0,
                        Agregar: item.Agregar?item.Agregar:0,
                        Editar: item.Editar?item.Editar:0,
                        Eliminar: item.Eliminar?item.Eliminar:0,
                        Exportar: item.Exportar?item.Exportar:0,
                        Propuesta: item.Propuesta?item.Propuesta:0,
                        Seguimiento: item.Seguimiento?item.Seguimiento:0,
                        Capturista:item.Capturista?item.Capturista:0,
                        ViewAll: item.ViewAll?item.ViewAll:0,
                        Electoral: item.Electoral?item.Electoral:0,
                        Territorial: item.Territorial?item.Territorial:0,
                        Social:item.Social?item.Social:0,
                    };
                }
            })
        }
    });

    return json;
}

export { getDataMenu };