const dataCurp = {
  CURP: "",
  apellido1: "",
  apellido2: "",
  nombres: "",
  sexo: "",
  fechNac: "",
  nacionalidad: "",
  docProbatorio: "",
  anioReg: "",
  foja: "",
  tomo: "",
  libro: "",
  numActa: "",
  CRIP: "",
  numEntidadReg: "",
  cveMunicipioReg: "",
  NumRegExtranjeros: "",
  FolioCarta: "",
  cveEntidadNac: "",
  cveEntidadEmisora: "",
  statusCurp: "",
  Precurp: null,
  Precurpxl: null,
  TipoError: null,
  message: null
};

export { dataCurp };
