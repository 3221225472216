import React /* , { useEffect } */ from "react";
import Navbar from "./Navbar";
import NavbarSinAuth from "./NavbarSinAuth";
import { /* setVars, */ getVars } from "../../utils/GlobalStorage";
const Header = (props) => {
  const token = getVars("Token");

  return (
    <>
      <header>
        <div
          id="nav-top-first"
          className="fixed-top gto-blue"
          style={{ fontSize: "12px", height: "32px" }}
        >
          <div className="container">
            <ul className="nav">
              <li className="nav-item hidden-md-down">
                <a
                  className="nav-link active white-text"
                  href="/"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-phone mr-1 align-middle"></i>
                  <small className="align-middle">(000) 000 0000</small>
                </a>
              </li>
              {/* <li className="nav-item hidden-md-down">
                <a
                  className="nav-link active white-text"
                  href="/"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-envelope-o mr-1 align-middle"></i>
                  <small className="align-middle">CONTÁCTANOS</small>
                </a>
              </li>
              <li className="nav-item hidden-md-down">
                <a
                  className="nav-link active  white-text"
                  href="/"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-wheelchair mr-1 align-middle disabled"></i>
                  <small className="align-middle">ACCESIBILIDAD</small>
                </a>
              </li> */}
              <li className="nav-item ml-auto justify-content-end">
                <span className="nav-link">
                  <a
                    className="white-text"
                    href="https://twitter.com/PANGuanajuato"
                    target="_blank"
                    rel="opener noreferrer"
                  >
                    <i className="fa fa-twitter fa-1x px-2 align-middle"></i>
                  </a>
                  <a
                    className="white-text"
                    href="https://www.facebook.com/PANGuanajuato/"
                    target="_blank"
                    rel="opener noreferrer"
                  >
                    <i className="fa fa-facebook fa-1x px-2 align-middle"></i>
                  </a>
                  <a
                    className="white-text"
                    href="https://www.youtube.com/channel/UCd8M0pWxmXnCJTN8hmjnROg"
                    target="_blank"
                    rel="opener noreferrer"
                  >
                    <i className="fa fa-youtube-play fa-1x px-2 align-middle"></i>
                  </a>
                </span>
              </li>
              {/* <li className="nav-item justify-content-end">
                <a
                  id="loginLink"
                  href="/Account/Login?returnUrl=llamadaDesdeHome"
                  className="white-text nav-link"
                >
                  <i className="fa fa-lock align-middle mr-2"></i>
                  <small className="align-middle">ACCESO</small>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        {}
        {Object.keys(token).length === 0 ? (
          <NavbarSinAuth></NavbarSinAuth>
        ) : (
          <Navbar
            {...props}
            menus={token.menus}
            bgColor="black"
            activeColor="info"
          ></Navbar>
        )}

        {/* <Sidebar
        {...props}
        menus={token.menus}
        bgColor="black"
        activeColor="info" */}
      </header>
    </>
  );
};
export default Header;
