import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const CompromisosDia = ({ dataDiaRevocacion }) => {
  const Options = {
    chart: {
      type: "line",
    },
    title: {
      text: dataDiaRevocacion.Title,
    },
    xAxis: {
      categories: dataDiaRevocacion.Categoria,
    },
    yAxis: {
      title: {
        text: "Compromisos",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        name: "Captura Compromisos",
        data: dataDiaRevocacion.data,
      },
    ],
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={Options} type="column" />
  );
};

export default CompromisosDia;
