import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Box, IconButton, InputAdornment, Stack } from '@mui/material';
import { getDate } from "../../utils/AuxiliarFunction";
import Select from "../Select/Select";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Search } from '@mui/icons-material';
import Notifications, { notify } from "react-notify-toast";
import { useFormik } from "formik";
import Yup from "../../utils/Yupi18n";
import { isEmptyOrInvalidString } from "../../utils/validations";


/**
 * 
 * *isCurp* Para Habilitar o deshabilitar el input de Curp
 * *datos* Enviar los datos para que se muestren
 * *send* para realizar el envio de todos los datos 
 * *activateButton* si quieren que se vea el boton 
 * updateData envia dato por dato al escribir en onChange
 * @returns Una alerta swal con el contenido pasado en elements
 */

const DatosPersonales = ({ datos, send, activateButton, updateData, isCurp, isOcupacion, isEstadoCivil, flagSubmit, reset }) => {

    let initialValues = {
        Curp: '',
        Nombre: '',
        Paterno: '',
        Materno: '',
        idEntidadNacimiento: '',
        FechaNacimiento: "2003-01-01",
        idSexo: '',
        idOcupacion: '',
        Ocupacion: '',
        idEstadoCivil: '',
    };
    let validationSchema = Yup.object({
        Curp: Yup.string().required().min(18).max(18).label("Curp"),
        Nombre: Yup.string().required().label("Nombre"),
        Paterno: Yup.string().required().label("Paterno"),
        Materno: Yup.string().required().label("Materno"),
        idEntidadNacimiento: Yup.number().required().label("Entidad de Nacimiento"),
        FechaNacimiento: Yup.date().default(() => new Date()).required().label("Fecha de Nacimiento"),
        idSexo: Yup.number().required().label("Sexo"),
        idOcupacion: Yup.number().required().label("Ocupación"),
        Ocupacion: Yup.string().when("idOcupacion", {
            is: 21,
            then: Yup.string().required("Comentario"),
        }).label("Ocupación"),
        idEstadoCivil: Yup.number().required().label("Estado Civil"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            send(values);
        }
    });

    const [sexo] = useState([
        { value: 2, label: "Masculino" },
        { value: 3, label: "Femenino" }
    ])

    const hadleInputs = (event) => {
        updateData(event)
        formik.handleChange(event)
        //errors(formik.errors);
    };


    const handleOnBlur = (event) => {
        updateData(event)
        formik.handleBlur(event)
        //errors(formik.errors);
    }

    async function handleCurp() {

        if (formik.values.Curp !== "") {
            let string_curp = `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${formik.values.Curp.toUpperCase()}/7`;
            let res_curp = await fetch(string_curp);
            let data_curp = await res_curp.json();
            if (data_curp && data_curp.Resultado !== undefined) {
                if (data_curp.Resultado !== null) {
                    formik.values.Nombre = data_curp.Resultado.nombres;
                    formik.values.Paterno = data_curp.Resultado.apellido1;
                    formik.values.Materno = data_curp.Resultado.apellido2;
                    formik.values.idEntidadNacimiento = parseInt(data_curp.Resultado.numEntidadReg, 10);
                    formik.values.FechaNacimiento = data_curp.Resultado.fechNac.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, '$3/$2/$1');
                    formik.values.idSexo = (data_curp.Resultado.sexo === "M" ? 3 : 2);
                    send(formik.values)
                    formik.handleSubmit(formik.values)

                }
                else {
                    notify.show(
                        `Curp: ${data_curp.Mensaje}`,
                        "error",
                        5000,
                        "#FFFFFF"
                    );
                }
            }

        } else {
            notify.show(
                `Coloque una curp`,
                "error",
                5000,
                "#FFFFFF"
            );
        }


    }

    useEffect(() => {
        if (reset) {
            formik.resetForm({ values: '', touched: false })
        }
        // eslint-disable-next-line
    }, [reset]);

    useEffect(() => {
        if (flagSubmit) {
            formik.handleSubmit(formik.values)
        }
        // eslint-disable-next-line
    }, [flagSubmit]);

    return (
        <div className="col-12 px-5 py-3">
            <Notifications />
            <Box
                sx={{
                    "& > :not(style)": { m: 1, width: "30ch" },
                }}
            >
                {
                    isCurp ?
                        (
                            <Stack direction="row" sx={{
                                "& > :not(style)": { m: 1, width: "30ch" },
                            }}>
                                <TextField
                                    label="Curp"
                                    variant="standard"
                                    id="curp"
                                    name="Curp"
                                    type="text"
                                    error={
                                        formik.touched.Curp &&
                                        !isEmptyOrInvalidString(formik.errors.Curp)
                                    }
                                    helperText={
                                        formik.touched.Curp &&
                                        formik.errors.Curp &&
                                        formik.errors.Curp
                                    }
                                    onBlur={handleOnBlur}
                                    value={formik.values.Curp}
                                    onChange={hadleInputs}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleCurp}>
                                                    <Search color="primary" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        ) : (<></>)
                }
                <TextField
                    required
                    label="Nombre"
                    name="Nombre"
                    variant="standard"
                    id="nombre"
                    type="text"
                    value={formik.values.Nombre}
                    onChange={hadleInputs}
                    error={
                        formik.touched.Nombre &&
                        !isEmptyOrInvalidString(formik.errors.Nombre)
                    }
                    helperText={
                        formik.touched.Nombre &&
                        formik.errors.Nombre &&
                        formik.errors.Nombre
                    }
                    onBlur={handleOnBlur}
                />
                <TextField
                    required
                    label="Paterno"
                    name="Paterno"
                    variant="standard"
                    id="paterno"
                    type="text"
                    value={formik.values.Paterno}
                    onChange={hadleInputs}
                    error={
                        formik.touched.Paterno &&
                        !isEmptyOrInvalidString(formik.errors.Paterno)
                    }
                    helperText={
                        formik.touched.Paterno &&
                        formik.errors.Paterno &&
                        formik.errors.Paterno
                    }
                    onBlur={handleOnBlur}
                />
                <TextField
                    required
                    label="Materno"
                    name="Materno"
                    variant="standard"
                    id="Materno"
                    type="text"
                    onChange={hadleInputs}
                    value={formik.values.Materno}
                    error={
                        formik.touched.Materno &&
                        !isEmptyOrInvalidString(formik.errors.Materno)
                    }
                    helperText={
                        formik.touched.Materno &&
                        formik.errors.Materno &&
                        formik.errors.Materno
                    }
                    onBlur={handleOnBlur}
                />
                <Select
                    required={true}
                    fullWidth={true}
                    title="Estado de Nacimiento"
                    api="getEntidades"
                    name="idEntidadNacimiento"
                    _selected={formik.values.idEntidadNacimiento}
                    onChange={(e) => { hadleInputs(e) }}
                    error={
                        formik.touched.idEntidadNacimiento &&
                        !isEmptyOrInvalidString(formik.errors.idEntidadNacimiento)
                    }
                    helperText={
                        formik.touched.idEntidadNacimiento &&
                        formik.errors.idEntidadNacimiento &&
                        formik.errors.idEntidadNacimiento
                    }
                    onBlur={handleOnBlur}
                />
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                >
                    <DesktopDatePicker
                        required={true}
                        label="Fecha de Nacimiento"
                        name="FechaNacimiento"
                        inputFormat="dd/MM/yyyy"
                        format="dd/MM/yyyy"
                        value={formik.values.FechaNacimiento}
                        onChange={(e) => {
                            // hadleInputs({target:{name:"FechaSolicitud", value:fecha}});
                        }}
                        onAccept={(e) => {
                            let fecha = getDate({ fecha: e })
                            hadleInputs({ target: { name: "FechaSolicitud", value: fecha } });
                        }}
                        error={
                            formik.touched.FechaNacimiento &&
                            !isEmptyOrInvalidString(formik.errors.FechaNacimiento)
                        }
                        helperText={
                            formik.touched.FechaNacimiento &&
                            formik.errors.FechaNacimiento &&
                            formik.errors.FechaNacimiento
                        }
                        renderInput={(params) => (
                            <TextField variant="standard" {...params} />
                        )}
                    />
                </LocalizationProvider>
                <Select
                    title="Sexo"
                    options={sexo}
                    _selected={formik.values.idSexo}
                    name="idSexo"
                    onChange={(e) => { hadleInputs(e) }}
                    error={
                        formik.touched.idSexo &&
                        !isEmptyOrInvalidString(formik.errors.idSexo)
                    }
                    helperText={
                        formik.touched.idSexo &&
                        formik.errors.idSexo &&
                        formik.errors.idSexo
                    }
                    onBlur={handleOnBlur}
                />
                {
                    isOcupacion ? (
                        <>
                            <Select
                                title="Ocupación"
                                _selected={formik.values.idOcupacion}
                                name="idOcupacion"
                                api="getOcupacion"
                                onChange={(e) => { hadleInputs(e) }}
                                error={
                                    formik.touched.idOcupacion &&
                                    !isEmptyOrInvalidString(formik.errors.idOcupacion)
                                }
                                helperText={
                                    formik.touched.idOcupacion &&
                                    formik.errors.idOcupacion &&
                                    formik.errors.idOcupacion
                                }
                                onBlur={handleOnBlur}
                            />
                            {formik.values.idOcupacion === 21 ? (
                                <TextField
                                    required
                                    label="Ocupación"
                                    name="Ocupacion"
                                    variant="standard"
                                    id="Ocupacion"
                                    type="text"
                                    onChange={hadleInputs}
                                    value={formik.values.Ocupacion}
                                    error={
                                        formik.touched.Ocupacion &&
                                        !isEmptyOrInvalidString(formik.errors.Ocupacion)
                                    }
                                    helperText={
                                        formik.touched.Ocupacion &&
                                        formik.errors.Ocupacion &&
                                        formik.errors.Ocupacion
                                    }
                                    onBlur={handleOnBlur}
                                />
                            ) : (<></>)
                            }
                        </>
                    ) : (<></>)
                }
                {
                    isEstadoCivil ? (
                        <Select
                            title="Estado Civil"
                            _selected={formik.values.idEstadoCivil}
                            name="idEstadoCivil"
                            api="getEstadoCivil"
                            onChange={(e) => { hadleInputs(e) }}
                            error={
                                formik.touched.idEstadoCivil &&
                                !isEmptyOrInvalidString(formik.errors.idEstadoCivil)
                            }
                            helperText={
                                formik.touched.idEstadoCivil &&
                                formik.errors.idEstadoCivil &&
                                formik.errors.idEstadoCivil
                            }
                            onBlur={handleOnBlur}
                        />
                    ) : (<></>)
                }
            </Box>
            {
                activateButton ?
                    <Grid conteiner sx={{ mt: 2 }} textAlign='right'>
                        <Button variant="contained" color="primary" type="button" style={{ borderRadius: "2em" }} round>
                            Guardar
                        </Button>
                    </Grid> :
                    <></>
            }
        </div>
    );
}
export default DatosPersonales;