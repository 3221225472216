import React from "react";
import {
    CardHeader
  } from "@mui/material";

const CardLine = ({ colors, title }) => {

    return (
        <CardHeader title={title} variant="dashed" 
            style={{ borderBottom: `2px dashed #638C1C`, color: colors , borderWidth:2.5}}
            sx={{ m: 1,mr: 3, ml: 3, mb: 0}}
            titleTypographyProps={{ align: "center", variant: 'h6' }}
        />
    );
}
export default CardLine;