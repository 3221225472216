import { Box } from "@mui/material";

import background from "../../assets/img/CRONOMETRO.png";
import "./font.css";

import Timer from "./Timer";

const Home = () => {
  return (
    <>
      <Box id="render-body" sx={{ minHeight: "100vh" }}>
        <Timer fecha="2027-06-06T00:59:59" />
        <Box sx={{ width: "100vw", height: "100vh" }}>
          <img
            src={background}
            alt="Imagen del pan"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>

      {/* <style>{`@media (min-width: 1400px) {img {object-fit: fill !important;}}`}</style> */}
    </>
  );
};

export default Home;
