import React, { useEffect } from "react";
import {
    Grid, TextField, Button, Box
} from '@mui/material';
import AutoComplete from "../AutoComplete/AutoComplete";
import { useFormik } from "formik";
import Yup from "../../utils/Yupi18n";
import { isEmptyOrInvalidString } from "../../utils/validations";

const DatosPersonales = ({ datos, send, activateButton, updateData, flagSubmit, reset }) => {
    let initialValues ={}
        initialValues = 
            datos?(
            {Colonia: datos.Colonia?datos.Colonia:'',
            idLocalidad:datos.idLocalidad?datos.idLocalidad:113314,
            Localidad:datos.Localidad?datos.Localidad:'Tlapa de Comonfort',
            Calle: datos.Calle?datos.Calle:'',
            NumExt: datos.NumExt?datos.NumExt:'',
            NumInt: datos.NumInt?datos.NumInt:'',
            CP: datos.CP?datos.CP:'',
            idMunicipio: datos.idMunicipio?datos.idMunicipio:440,
            Municipio: datos.Municipio?datos.Municipio:'Tlapa de Comonfort',
            Entidad:'',
            Latitud: datos.Latitud?datos.Latitud:'',
            Longitud: datos.Longitud?datos.Longitud:''}):(
            {Colonia: '',
            idLocalidad:113314,
            Localidad:'Tlapa de Comonfort',
            Calle:'',
            NumExt:'',
            NumInt:'',
            CP: '',
            idMunicipio:440,
            Municipio:'Tlapa de Comonfort',
            Entidad:12,
            Latitud:'',
            Longitud:''}
            )
   
    let validationSchema = Yup.object({
        Calle: Yup.string().required().label("Calle"),
       // Colonia: Yup.number().required().label("Colonia"),
        idLocalidad: Yup.string().required().label("Localidad"),
        NumExt: Yup.string().required("Num Ext").label("Num Ext"),
        NumInt: Yup.string().label("Num Int"),
        CP: Yup.number().required("Codigo Postal").min(1,"Codigo Postal min 1 digitos").label("Codigo Postal"),
        idMunicipio: Yup.number().required().label("Municipio"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            send(values);
        }
    });

    /*  const [colonias, setColonias] = useState([]); */
    /*  const [localidad, setLocalidad] = useState([]); */


    const hadleInputs = (event) => {
        updateData(event)
        formik.handleChange(event)
        //errors(formik.errors);
};


const handleOnBlur = (event) =>{
    updateData(event)
    formik.handleBlur(event)
    //errors(formik.errors);
}

    useEffect(() => {
        if (flagSubmit) {
            formik.handleSubmit(formik.values)
            send(formik.values)
        }
        // eslint-disable-next-line
    }, [flagSubmit]);

    useEffect(() => {
        if (reset) {
            formik.resetForm({values:'',touched:false})
        }
        // eslint-disable-next-line
    }, [reset]);

    return (
        <>
            <div className="col-12 px-5 py-3">
                <Box
                    sx={{
                        "& > :not(style)": { m: 1, width: "30ch" },
                    }}
                >
                    <AutoComplete
                        title="Municipio"
                        api="getMunicipios"
                        name="idMunicipio"
                        fil="Municipio"
                        reset={reset}
                        error={
                            formik.touched.idMunicipio &&
                            !isEmptyOrInvalidString(formik.errors.idMunicipio)
                        }
                        helperText={
                            formik.touched.idMunicipio &&
                            formik.errors.idMunicipio &&
                            formik.errors.idMunicipio
                        }
                        value={{value:formik.values.idMunicipio, label:formik.values.Municipio}}
                        onChange={(e)=>{
                            formik.values.idMunicipio = e.value;
                            formik.values.Municipio=e.label;
                            formik.values.Entidad=e.idEntidad;
                            send(formik.values)
                            formik.handleSubmit(formik.values)
                        } }
                    >
                    </AutoComplete>
                    {/*  <AutoComplete
                            name="Colonia"
                            title="Colonia"
                        /> */}
                    {
                        formik.values.idMunicipio !== 0 ?
                            (
                                <AutoComplete
                                    name="idLocalidad"
                                    title="Localidad"
                                    api="getLocalidades"
                                    fil="Localidad"
                                    value={{value:formik.values.idLocalidad,label:formik.values.Localidad}}
                                    reset={reset}
                                    onChange={(e)=>{
                                        console.log("localidad",e)
                                        formik.values.idLocalidad = e.value
                                        formik.values.Localidad = e.label
                                        send(formik.values)
                                        formik.handleSubmit(formik.values)
                                    }}
                                    params={[{"id": "idMunicipio", "value": formik.values.idMunicipio, "filter": "="}]}
                                    error={
                                        formik.touched.idLocalidad &&
                                        !isEmptyOrInvalidString(formik.errors.idLocalidad)
                                    }
                                    helperText={
                                        formik.touched.idLocalidad &&
                                        formik.errors.idLocalidad &&
                                        formik.errors.idLocalidad
                                    }
                                />
                            ) : (<></>)
                    }

                    <TextField
                        required
                        label="Calle"
                        variant="standard"
                        id="Calle"
                        name="Calle"
                        type="text"
                        error={
                            formik.touched.Calle &&
                            !isEmptyOrInvalidString(formik.errors.Calle)
                        }
                        helperText={
                            formik.touched.Calle &&
                            formik.errors.Calle &&
                            formik.errors.Calle
                        }
                        onBlur={handleOnBlur}
                        value={formik.values.Calle}
                        onChange={hadleInputs}
                    />
                    <TextField
                        required
                        id="NumExt"
                        label="Numero Exterior"
                        type="text"
                        name="NumExt"
                        variant="standard"
                        error={
                            formik.touched.NumExt &&
                            !isEmptyOrInvalidString(formik.errors.NumExt)
                        }
                        helperText={
                            formik.touched.NumExt &&
                            formik.errors.NumExt &&
                            formik.errors.NumExt
                        }
                        onBlur={handleOnBlur}
                        value={formik.values.NumExt}
                        onChange={hadleInputs}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="NumInt"
                        label="Numero Interior"
                        type="text"
                        name="NumInt"
                        variant="standard"
                        error={
                            formik.touched.NumInt &&
                            !isEmptyOrInvalidString(formik.errors.NumInt)
                        }
                        helperText={
                            formik.touched.NumInt &&
                            formik.errors.NumInt &&
                            formik.errors.NumInt
                        }
                        onBlur={handleOnBlur}
                        value={formik.values.NumInt}
                        onChange={hadleInputs}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        required
                        id="CodigoPostal"
                        label="Codigo Postal"
                        type="number"
                        name="CP"
                        variant="standard"
                        error={
                            formik.touched.CP &&
                            !isEmptyOrInvalidString(formik.errors.CP)
                        }
                        helperText={
                            formik.touched.CP &&
                            formik.errors.CP &&
                            formik.errors.CP
                        }
                        onBlur={handleOnBlur}
                        value={formik.values.CP}
                        onChange={hadleInputs}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                {
                    activateButton ?
                        <Grid conteiner sx={{ mt: 2 }} textAlign='right'>
                            <Button variant="contained" color="primary" type="button" style={{ borderRadius: "2em" }} round>
                                Guardar
                        </Button>
                        </Grid> :
                        <></>
                }
            </div>
        </>
    );
}
export default DatosPersonales;