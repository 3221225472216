import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Container,
  Button,
  CardActions,
  TextField,
  Stack,
  Typography
} from "@mui/material";
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import DatosPersonales from "../../components/DatosPersonales/DatosPersonales";
import Domicilio from "../../components/DatosPersonales/Domicilio";
import Contactos from "../../components/DatosPersonales/Contactos";
import Integrantes from "../../components/DatosPersonales/Integrantes";
import ApiExec from "../../utils/ApiExec";
import { getVars } from "../../utils/GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import CAlertList from "./../../components/Alerts/CAlertList";
import CardLine from "../../components/CardHeader/CardLine"
import { MetroSpinner } from "react-spinners-kit";
import Select from "../../components/Select/Select";
import { useFormik } from "formik";
import Yup from "../../utils/Yupi18n";
import { isEmptyOrInvalidString } from "../../utils/validations";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { getDate } from "../../utils/AuxiliarFunction";
import { getDataMenu } from './../../data/menus';

const AddSolicitud = () => {
  const token = getVars("Token");
  const [_menu, _setmenu] = useState(getDataMenu(6, token.menus));

  let initialValues = {
    Curp: '',
    Nombre: '',
    Paterno: '',
    Materno: '',
    TelCel: '',
    TelFijo: '',
    TelRecados: '',
    Correo: '',
    idEntidadNacimiento: '',
    FechaNacimiento: getDate({}),
    FechaSolicitud: getDate({}),
    Colonia: '',
    idLocalidad: 113314,
    Localidad: 'Tlapa de Comonfort',
    Calle: '',
    NumExt: '',
    NumInt: '',
    CP: '',
    idSexo: '',
    idMunicipio: 440,
    Municipio: 'Tlapa de Comonfort',
    idOcupacion: '',
    Ocupacion: '',
    idEstadoCivil: '',
    Comentario: '',
    idProgramaSocial: '',
    correo: '',
    TotalIntegrantes: 0,
    TotalMayores: 0,
    TotalMenores: 0
  };
  let validationSchema = Yup.object({
    idProgramaSocial: Yup.number().required().label("Programa"),
    Curp: Yup.string().min(18).max(18).label("Curp"),
    Nombre: Yup.string().required().label("Nombre"),
    Paterno: Yup.string().required().label("Paterno"),
    Materno: Yup.string().required().label("Materno"),
    idEntidadNacimiento: Yup.number().required().label("Estado de Nacimiento"),
    FechaNacimiento: Yup.date().default(() => new Date()).required().label("Fecha de Nacimiento"),
    FechaSolicitud: Yup.date().default(() => new Date()).required().label("Fecha de Nacimiento"),
    idSexo: Yup.number().required().label("Sexo"),
    idOcupacion: Yup.number().required().label("Ocupación"),
    Ocupacion: Yup.string().when("idOcupacion", {
      is: 21,
      then: Yup.string().required("Ocupación"),
    }).label("Ocupación"),
    idEstadoCivil: Yup.number().required().label("Estado Civil"),
    TelCel: Yup.string().required().label("Celular"),
    TelFijo: Yup.string().min(10).max(10).label("Telefono"),
    TelRecados: Yup.string().min(10).max(10).label("Telefono Recado"),
    Correo: Yup.string().email().label("Correo"),
    Comentario: Yup.string().label("Comentario"),
    Calle: Yup.string().required().label("Calle"),
    //Colonia: Yup.number().required().label("Colonia"),
    idLocalidad: Yup.number().required().label("Localidad"),
    NumExt: Yup.string().required("Num Ext").label("Num Ext"),
    NumInt: Yup.string().label("Num Int"),
    CP: Yup.number().required("Codigo Postal").label("Codigo Postal"),
    idMunicipio: Yup.number().required().label("Municipio"),
    TotalMayores: Yup.number().label('Total de Mayores'),
    TotalMenores: Yup.number().label('Total de Menores'),
    TotalIntegrantes: Yup.number().label('Total Integrantes'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema
  });
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    _setmenu(getDataMenu(6, token.menus))
    formik.handleSubmit(formik.values)
    // eslint-disable-next-line
  }, []);

  const handleSave = () => {

    let flag = Object.values(formik.errors);
    if (formik.values.TotalIntegrantes !== (formik.values.TotalMayores + formik.values.TotalMenores)) {
      flag.push(`La sumatoria de los integrantes mayores y menores no coincide con el total ${formik.values.TotalIntegrantes}`)
    }
    if (flag.length > 0) {
      onSubmitComponents(true)
      CAlertList({ elements: flag, title: "No se pudo registrar:", icon: "warning" })
      onSubmitComponents(false)
    }
    else {
      let dato = {
        ...formik.values,
        Nombre: formik.values.Nombre.toUpperCase(),
        Paterno: formik.values.Paterno.toUpperCase(),
        Materno: formik.values.Materno.toUpperCase(),
        Calle: formik.values.Calle.toUpperCase(),
        Mayores18:formik.values.TotalMayores,
        Menores18:formik.values.TotalMenores,
        OcupacionOtro: formik.values.Ocupacion,
        Sexo: formik.values.idSexo === 3 ? 'M' : 'H'
      }
      ApiExec({ data: [dato] }, "setSolicitudSync", token.access_token, {})
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            if (res.aceptados.length > 0) {
              formik.resetForm({ values: '' })
              formik.handleSubmit(formik.values)
              onSubmitReset(true)
              CAlertList({ elements: [res.aceptados[0].NombreCompleto, "Solicitud Guardada Correctamente"], title: "Registro Exitoso!", icon: "success" })
              onSubmitComponents(false)
              onSubmitReset(false)
            }
            else if (res.rechazados.length > 0) {
              CAlertList({ elements: [res.rechazados[0].NombreCompleto], title: "Solicitud ya se encuentra registrada!", icon: "warning" })
              onSubmitComponents(false)
            }

          } else {
            onSubmitComponents(false)
            CAlertList({ elements: res.message, title: "Solicitud No registrada: ", icon: "warning" })
            notify.show(
              `Error de solicitud: ${res.message}`,
              "error",
              5000,
              "#FFFFFF"
            );
          }
        })
        .catch((e) => {
          onSubmitComponents(false)
          notify.show(`Error setSolicitudSync! ${e.message}`, "custom", 5000, {
            background: "#0E1717",
            text: "#FFFFFF",
          });
        });
      onSubmitComponents(false)
    }


  }


  const hadleInputs = (event) => {
    formik.handleChange(event)
  };

  async function onSubmitComponents(data) {
    let res = await setTimeout(function () { return true }, 100);
    if (res) {
      setLoading(data);
    }
  }

  async function onSubmitReset(data) {
    let res = await setTimeout(function () { return true }, 100);
    if (res) {
      setReset(data);
    }
  }

  const handleOnSubmit = () => {
    onSubmitComponents(true)
    formik.handleSubmit(formik.values)
    handleSave()
  }

  const sumbmit = (e) => {
    Object.keys(e).forEach((item) => {
      formik.values[item] = e[item]
    })
    formik.handleSubmit(formik.values)

  }

  return (
    <>
      <Notifications />
      <Header></Header>
      <Container id="container" className="pt-10">
        <Grid container>
          {
            _menu.Agregar === 1 ? (
              <Grid item sm={12} md={12} lg={12} className="pr-0">
                <Container className="p-0 pb-4">
                  <Card sx={{ pb: 2 }}>
                    <CardHeader sx={{ mr: 3, ml: 3, mb: 0, background: "#638C1C" }}
                      title={"Formulario de Solicitud"}
                      titleTypographyProps={{ align: "center", color: "white", variant: 'h4' }}></CardHeader>
                    <CardLine colors="#638C1C" title={"DATOS DE ACCESO"} />
                    <CardActions sx={{ mt: 7, ml: 7, width: "100%" }}>
                      <Stack direction="row" sx={{
                        "& > :not(style)": { m: 1, width: "30ch" },
                      }}>
                        <Select
                          required={true}
                          fullWidth={true}
                          title="Programa"
                          name="idProgramaSocial"
                          api="getProgramasSociales"
                          _selected={formik.values.idProgramaSocial}
                          onChange={(e) => { hadleInputs(e) }}
                          error={
                            formik.touched.idProgramaSocial &&
                            !isEmptyOrInvalidString(formik.errors.idProgramaSocial)
                          }
                          helperText={
                            formik.touched.idProgramaSocial &&
                            formik.errors.idProgramaSocial &&
                            formik.errors.idProgramaSocial
                          }
                          onBlur={formik.handleBlur}
                        />
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                        >
                          <DesktopDatePicker
                            required={true}
                            label="Fecha de Solicitud"
                            name="FechaSolicitud"
                            inputFormat="dd/MM/yyyy"
                            format="dd/MM/yyyy"
                            value={formik.values.FechaSolicitud}
                            onChange={(e) => {
                              /*  let fecha = { e }
                               if (e === /^(\d{2})\/(\d{2})\/(\d{4})$/g) {
                                 fecha = getDate({ fecha: e })
 
                               } */
                              // hadleInputs({target:{name:"FechaSolicitud", value:fecha}});
                            }}
                            onAccept={(e) => {
                              let fecha = getDate({ fecha: e })
                              hadleInputs({ target: { name: "FechaSolicitud", value: fecha } });
                            }}
                            error={
                              formik.touched.FechaSolicitud &&
                              !isEmptyOrInvalidString(formik.errors.FechaSolicitud)
                            }
                            helperText={
                              formik.touched.idProgramaSocial &&
                              formik.errors.idProgramaSocial &&
                              formik.errors.idProgramaSocial
                            }
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                              <TextField variant="standard" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </CardActions>
                    <CardContent className="p-0">
                      <Container>
                        <Grid container>
                          <Grid item md={12} sm={12} lg={12}>
                            <form className="form-horizontal">
                              <Grid item md={12} sm={12} xl={12}>
                                <CardLine colors="#638C1C" title={"DATOS PERSONALES"} />
                                <DatosPersonales send={sumbmit} updateData={hadleInputs} flagSubmit={loading} reset={reset} isCurp={true} isOcupacion={true} isEstadoCivil={true} />
                                <CardLine colors="#638C1C" title={"DOMICILIO"} />
                                <Domicilio send={sumbmit} updateData={hadleInputs} reset={reset} flagSubmit={loading} />
                                <CardLine colors="#638C1C" title={"CONTACTO"} />
                                <Contactos send={sumbmit} updateData={hadleInputs} reset={reset} isComentario={true} isCorreo={false} flagSubmit={loading} />
                                <CardLine colors="#638C1C" title={"DATOS FAMILIARES"} />
                                <Integrantes send={sumbmit} updateData={hadleInputs} reset={reset} flagSubmit={loading} />
                              </Grid>
                            </form>
                          </Grid>
                        </Grid>
                        <Grid sx={{ mt: 2 }} textAlign='center'>
                          {loading ? (<MetroSpinner
                            size={30}
                            color="#2285E7"
                            loading={loading}
                          />) : (
                              <Button variant="contained" color="primary" onClick={handleOnSubmit} style={{ borderRadius: "2em" }} >
                                Guardar
                              </Button>
                            )}
                        </Grid>
                      </Container>
                    </CardContent>
                  </Card>
                </Container>
              </Grid>

            ) : (
                <Card>
                  <Typography variant="h2">
                    No cuenta con los permisos necesarios contacte al administrador
            </Typography>
                </ Card>
              )
          }
        </Grid>
      </Container>
      <Footer></Footer>
    </>
  );
};
export default AddSolicitud;
