import React from "react";
import {
  Grid
  , Card
  , Box
  , Paper
  , Button
  , Divider
  , Container
  , CardContent
} from "@mui/material";
import Icon from '@mui/material/Icon';
import Header from "../../components/MainPage/Header";
import Footer from "../../components/MainPage/Footer";
import CardTotal from "../../components/Comite/Box"
import Tablacomite from "./../../components/Comite/TablaComiteSolicitudes";

const ComiteSolicitud = () => {

  const columns = [
    { id: "Folio", label: "Folio" },
    { id: "Fechasolicitud", label: "Fecha Solicitud" },
    { id: "Curp", label: "Curp" },
    { id: "NombreCompleto", label: "Nombre Completo" },
    { id: "Programa", label: "Programa social" },
    { id: "Remesa", label: "Remesa" },
    { id: "Estatus", label: "Estatus" }
  ];

  const handleChangePagination = (e) => {
  };

  const Rows = [
    {
      id: 1,
      Folio: "0001",
      Fechasolicitud: "2021/10/16",
      Curp: 'JULM970710MCSRRZ06',
      NombreCompleto: 'MONICA JUAREZ LUNA',
      Programa: 'EMPLEO TEMPORAL',
      Remesa: '006',
      Estatus: 'EN PROCESO',
    }, {
      id: 2,
      Folio: "0001",
      Fechasolicitud: "2021/10/16",
      Curp: 'PINCL970710MCSRRZ06',
      NombreCompleto: 'LUIS MANUEL PINTO CASAREZ',
      Programa: 'EMPLEO TEMPORAL',
      Remesa: '006',
      Estatus: 'EN PROCESO',
    },
    {
      id: 3,
      Folio: "0001",
      Fechasolicitud: "2021/10/16",
      Curp: 'SACE970710MCSRRZ06',
      NombreCompleto: 'EDITH SANCHEZ CRUZ',
      Programa: 'EMPLEO TEMPORAL',
      Remesa: '006',
      Estatus: 'EN PROCESO',
    },
    {
      id: 4,
      Folio: "0001",
      Fechasolicitud: "2021/10/16",
      Curp: 'VAJJ970710MCSRRZ06',
      NombreCompleto: 'JOEL VAZQUEZ JIMENEZ',
      Programa: 'EMPLEO TEMPORAL',
      Remesa: '006',
      Estatus: 'EN PROCESO',
    },
    {
      id: 5,
      Folio: "0001",
      Fechasolicitud: "2021/10/16",
      Curp: 'SOPM970710MCSRRZ06',
      NombreCompleto: 'MARIA DEL SOCORRO SOLIS PEREZ',
      Programa: 'EMPLEO TEMPORAL',
      Remesa: '006',
      Estatus: 'EN PROCESO',
    },
  ]

  return (
    <>
      <Header></Header>
      <Container id="container" className="pt-10">
        <Grid container>
          <Grid sm="12" md="12" lg="12" className="pr-0">
            <Container className="p-0 pb-4">
              <Card sx={{ pb: 2 }}>
                <CardContent className="p-0">
                  <Container>
                    <Box >
                      <Button
                        href="#/comites"
                        sx={{ m: 3, maxWidth: 120 }}
                        variant="outlined"
                        size="small"
                        color="success"
                        startIcon={<Icon>arrow_back</Icon>}
                      >
                        Regresar
                      </Button>
                      <Divider
                        sx={{ mb: 3 }}
                      />
                      <Grid md="4" sm="4" lg="4" >
                        <Paper elevation="2" sx={{ borderRadius: 2, mb:1}}>
                          <CardTotal/>
                        </Paper>
                      </Grid>
                      <Grid container >
                        <Grid item xs={12} md={12} lg={12}>
                          <Tablacomite
                            data={Rows}
                            hcolumns={columns}
                            rowCount={Rows}
                            pageSize={5}
                            pageProp={1}
                            Dato="Emergencias"
                            handleChangePagination={(e) =>
                              handleChangePagination(e)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </>
  );
};
export default ComiteSolicitud;
