import React, { useState, useEffect } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircleSpinner } from "react-spinners-kit";

const BarrasDobles = (dataInfo) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    //console.log("=>Update Dona: ", dataInfo.dataInfo);
    setData({
      chart: {
        type: "column",
        height: "320px",
      },
      colors: ["#07328E", "#B5261E"],
      title: {
        text: dataInfo.dataInfo.title,
      },

      subtitle: {
        text: "Comparativo de resultados con Revocación",
      },

      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },

      xAxis: {
        categories: dataInfo.dataInfo.categories,
        labels: {
          x: -10,
        },
      },

      yAxis: {
        allowDecimals: false,
        title: {
          text: "Votación",
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        floating: true,
        backgroundColor: "#FFFFFF",
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y, 0);
            },
          },
        },
      },
      series: [
        {
          name: "PAN",
          data: dataInfo.dataInfo.pan,
        },
        {
          name: "MORENA",
          data: dataInfo.dataInfo.morena,
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal",
              },
              yAxis: {
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    });
  }, [dataInfo]);

  return data !== null ? (
    <HighchartsReact highcharts={Highcharts} options={data} type="column" />
  ) : (
    <CircleSpinner
      className="text-center"
      size={30}
      color="#686769"
      loading={true}
    />
  );
};

export default BarrasDobles;
