import Yup from "../../utils/Yupi18n"

const ComiteEditSchema = Yup.object({
	Leyenda: Yup.string().required().label("Leyenda"),
	idPrograma: Yup.number().required().label("Programa Social"),
	Fecha: Yup.date().default(() => new Date()).required().label("Fecha"),
	idTipo: Yup.number().required().label("Tipo de Comite"),
});

const ComiteAddSchema = Yup.object({
	Leyenda: Yup.string().required().label("Leyenda"),
	idPrograma: Yup.number().required().label("Programa Social"),
	Fecha: Yup.date().default(() => new Date()).required().label("Fecha"),
	idTipo: Yup.number().required().label("Tipo de Comite"),
});


export {
	ComiteEditSchema,
	ComiteAddSchema
}